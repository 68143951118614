import { AutocompleteInputChangeReason } from '@mui/material';
import { last } from 'fp-ts/lib/Array';
import { constant, pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Option';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { ConfigContext } from '../../context/config.context';
import { LiveChatContext } from '../../context/live-chat-context';
import { ServicesContext } from '../../context/services.context';
import { SessionContext } from '../../context/session.context';
import { TranslationsContext } from '../../context/translations.context';
import { isSomeCustomUIComponentByResponseType, isTextMessage } from '../../models/message.model';
import { AutocompleteOption, InputAutocomplete } from '../../ui-kit/input-autocomplete/input-autocomplete.component';
import { filterEmptyObjects } from '../../utils/array.utils';
import { isMobileDeviceByTouchPoints } from '../../utils/device.utils';
import { Effect } from '../../utils/function.utils';
import { isEmpty } from '../../utils/string.utils';
import { Nullable } from '../../utils/types.utils';
import { isSendFreeMessageInAutocompleteDisabled } from './user-input.container.model';
import { useUserInputContainerStyles } from './user-input.container.styles';

interface UserInputContainerProps {
	isTextInputDisabled?: boolean;
	isSearchAutocompleteForm?: boolean;
	onOptionSelect?: Effect<AutocompleteOption>;
	onClear?: Effect<void>;
}

const TYPING_INDICATOR_TIMEOUT_DELAY = 5000;
const TYPING_INDICATOR_THROTTLE_DELAY = 500;

let debounceTimeout: NodeJS.Timeout;
let typingTimeout: NodeJS.Timeout;
let messageIsSent = false;
let lastTypingSentTimestamp: number | undefined;
let isThrottleTypings = false;

export const UserInputContainer: FC<UserInputContainerProps> = memo(
	({ isTextInputDisabled = false, isSearchAutocompleteForm = false, onOptionSelect, onClear }) => {
		const {
			i18n: { language },
			t,
		} = useTranslation();

		const { translations } = useContext(TranslationsContext);

		const {
			state: { autocompleteUri, shownMessages, isLoading: isWaitingWidgetMessage, onSendMessage },
		} = useContext(SessionContext);
		const {
			webTrackerService: { sendEvent },
		} = useContext(ServicesContext);
		const {
			state: { status, isLiveChatShown, isLiveChatSessionOver, newMessagesIndex },
			onUpdate,
		} = useContext(LiveChatContext);
		const {
			appConfig: {
				visualConfig: { assistantName },
			},
		} = useContext(ConfigContext);
		const { getAutocompleteSuggestions } = useContext(APIServiceContext);

		const containerClasses = useUserInputContainerStyles();

		const [isLoading, onLoadingStateChange] = useState(false);
		const [isTyping, setIsTyping] = useState(false);
		const [shouldRefetch, setShouldRefetch] = useState(false);
		const [inputValue, onInputValueChange] = useState<string>('');
		const [autocompleteValue, onAutocompleteValueChange] = useState<Nullable<AutocompleteOption>>(null);
		const [autocompleteOptions, updateAutocompleteOptions] = useState<AutocompleteOption[]>([]);

		const handleClear = () => {
			onAutocompleteValueChange(null);
			onInputValueChange('');
			updateAutocompleteOptions([]);
			onClear?.();
		};

		const handleSelectOption = (option: AutocompleteOption) => {
			if (isSearchAutocompleteForm) {
				onOptionSelect?.(option);
			} else {
				onSendMessage(option.value, option.label);
				handleClear();
			}
		};

		const fetchAutocompleteSuggestions = (value: string): void => {
			onLoadingStateChange(true);
			const autocompleteFullUri = `${autocompleteUri}&term=${value}`;
			getAutocompleteSuggestions(autocompleteFullUri)
				.then((data) => {
					onLoadingStateChange(false);

					!messageIsSent && updateAutocompleteOptions(filterEmptyObjects(data.results));
				})
				.catch(() => {
					onLoadingStateChange(false);
				});
		};

		useEffect(() => {
			// If response has come after input value was cleared or the step was changed to one without autocompleteUri
			// when options exists, we need to clear those results
			if ((inputValue === '' && autocompleteOptions.length) || (autocompleteOptions.length && !autocompleteUri)) {
				updateAutocompleteOptions([]);
			}
		}, [inputValue, autocompleteOptions, autocompleteUri]);

		useEffect(() => {
			if (isLiveChatShown && !isLiveChatSessionOver) {
				const message = isTyping ? 'startTyping' : 'endTyping';
				onSendMessage(message, '', false);
			}
		}, [isTyping]);

		// If language has changed meanwhile some suggestions were shown - set flag to refetch suggestions to fetch with a new language
		useEffect(() => {
			if (autocompleteOptions.length && !isEmpty(inputValue)) {
				setShouldRefetch(true);
			}
		}, [language]);

		useEffect(() => {
			if (shouldRefetch && !isWaitingWidgetMessage) {
				fetchAutocompleteSuggestions(inputValue);
				setShouldRefetch(false);
			}
		}, [isWaitingWidgetMessage]);

		const createTypingTimeout = (shouldClearPreviousTimeout?: boolean) => {
			shouldClearPreviousTimeout && clearTimeout(typingTimeout);
			typingTimeout = setTimeout(() => {
				setIsTyping(false);
				lastTypingSentTimestamp = Date.now();
			}, TYPING_INDICATOR_TIMEOUT_DELAY);
		};

		const handleInputValueChange = (val: string, reason?: AutocompleteInputChangeReason) => {
			clearTimeout(debounceTimeout);
			messageIsSent = false;
			if (isLiveChatShown && !isThrottleTypings) {
				if (isTyping) {
					createTypingTimeout(true);
				} else if (
					lastTypingSentTimestamp &&
					Date.now() - lastTypingSentTimestamp < TYPING_INDICATOR_THROTTLE_DELAY
				) {
					// Throttle isTyping to make sure we don't send 2 typings indicators simultaneously
					isThrottleTypings = true;
					setTimeout(() => {
						setIsTyping(true);
						createTypingTimeout();
						isThrottleTypings = false;
					}, TYPING_INDICATOR_THROTTLE_DELAY);
				} else {
					setIsTyping(true);
					createTypingTimeout();
				}
			}

			if (val === '') {
				return handleClear();
			}

			if (reason !== 'reset') {
				onInputValueChange(val);
				debounceTimeout = setTimeout(() => {
					if (autocompleteUri) {
						fetchAutocompleteSuggestions(val);
					}
				}, 300);
			}
		};

		const handleSendMessage = (value: string) => {
			messageIsSent = true;
			clearTimeout(debounceTimeout);
			sendEvent('onUserMessage', value);
			onSendMessage(value);
			handleClear();
		};

		const handleFocus = () => {
			if (newMessagesIndex) {
				onUpdate({ type: 'SET_NEW_MESSAGE_INDEX', payload: null });
			}
		};

		const isChatWaiting = status === 'WAITING' || status === 'TRANSFERING';
		const isInputBlocked = isTextInputDisabled || (isLiveChatShown && (isChatWaiting || isLiveChatSessionOver));

		const shouldFocusTextInput = pipe(
			shownMessages,
			last,
			fold(
				constant(false),
				(message) =>
					!message.incoming &&
					isTextMessage(message) &&
					!isSomeCustomUIComponentByResponseType(message.responseType) &&
					!isMobileDeviceByTouchPoints(),
			),
		);
		const isSendFreeTextDisabled =
			isSendFreeMessageInAutocompleteDisabled(autocompleteUri) || isSearchAutocompleteForm;

		const placeholder =
			translations.inputPlaceholder || `${t('userInputPlaceholder', 'Message')} ${assistantName ?? ''}`;

		return (
			<div className={containerClasses.root}>
				<InputAutocomplete
					isTextInputDisabled={isInputBlocked}
					isSendFreeTextDisabled={isSendFreeTextDisabled}
					onValueChange={handleInputValueChange}
					onOptionSelect={handleSelectOption}
					onSendMessage={handleSendMessage}
					options={autocompleteOptions}
					inputValue={inputValue}
					onClear={handleClear}
					value={autocompleteValue}
					isLoading={isLoading}
					onFocus={handleFocus}
					shouldFocusTextInput={shouldFocusTextInput && !isInputBlocked}
					placeholder={placeholder}
				/>
			</div>
		);
	},
);
