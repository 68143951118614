import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { Lazy } from '../../utils/function.utils';
import { Z_INDEX_TOP } from '../../utils/sizes.utils';
import { Icon } from '../icon/icon.component';
import {
	StyledFancyBoxContentWrapper,
	StyledFAncyBoxHeader,
	StyledFancyBoxIconButton,
	StyledFancyBoxModal,
} from './fancy-box.styles';

interface FancyBoxProps {
	isOpen: boolean;
	url: string;
	onClose: Lazy<void>;
}

export const FancyBox: FC<FancyBoxProps> = ({ isOpen, url, onClose }) => {
	const {
		palette: { transitionConfig },
	} = useTheme();

	return (
		<StyledFancyBoxModal
			style={{ zIndex: Z_INDEX_TOP + 1 }}
			disablePortal
			disableAutoFocus
			open={isOpen}
			onClose={onClose}
			aria-labelledby={'simple-modal-title'}
			aria-describedby={'simple-modal-description'}>
			<Slide
				in={isOpen}
				direction={'up'}
				timeout={{
					enter: transitionConfig.transitionInDuration,
					exit: transitionConfig.transitionOutDuration,
				}}>
				<StyledFancyBoxContentWrapper>
					<StyledFAncyBoxHeader>
						<StyledFancyBoxIconButton onClick={onClose} data-testing-label={'fancy-box-close-button'}>
							<Icon icon={CloseRoundedIcon} size={'medium'} iconType={'inputIcon'} />
						</StyledFancyBoxIconButton>
					</StyledFAncyBoxHeader>
					<iframe
						title={'gyantWidget'}
						style={{
							width: '100%',
							height: '100%',
							backgroundColor: '#FFFFFF',
							border: 'none',
						}}
						src={url}
					/>
				</StyledFancyBoxContentWrapper>
			</Slide>
		</StyledFancyBoxModal>
	);
};
