import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '../../../utils/types.utils';
import { ThumbsDownIcon } from '../../icons/thumbsDown';
import { ThumbsDownOutlineIcon } from '../../icons/thumbsDownOutline';
import { ThumbsUpIcon } from '../../icons/thumbsUp';
import { ThumbsUpOutlineIcon } from '../../icons/thumbsUpOutline';
import {
	StyledHelpfulnessSurveyRoot,
	StyledHelpfulnessSurveyText,
	StyledIcon,
	StyledIconButton,
} from './helpfulness-survey.styles';

export interface HelpfulnessSurveyProps {
	action(rating: number): void;
	className?: string;
}

type Rating = 1 | 2;

export const HelpfulnessSurvey: FC<HelpfulnessSurveyProps> = ({ action, className }) => {
	const { t } = useTranslation();
	const [selectedIcon, setSelectedIcon] = useState<Nullable<Rating>>(null);
	const [animationClassName, setAnimationClassName] = useState<string>();
	const onSelectButton = (rate: Rating) => {
		if (rate !== selectedIcon) {
			setSelectedIcon(rate);
			action(rate);
		}
	};

	useEffect(() => {
		if (selectedIcon) {
			setAnimationClassName(selectedIcon === 1 ? 'fabric-fd-thumbsDown' : 'fabric-fd-thumbsUp');
		}
	}, [selectedIcon]);

	const thumbs = (
		<Fragment>
			<StyledIconButton
				aria-label={`thumbs down${selectedIcon === 1 ? ' voted' : ''}`}
				onClick={() => onSelectButton(1)}
				size={'medium'}>
				<StyledIcon
					className={selectedIcon === 1 ? animationClassName : ''}
					icon={selectedIcon === 1 ? ThumbsDownIcon : ThumbsDownOutlineIcon}
					alt={'thumbs down'}
					iconType={'systemIconDefault'}
					viewBox={'0 0 20 20'}
					size={'small'}
				/>
			</StyledIconButton>
			<StyledIconButton
				aria-label={`thumbs up${selectedIcon === 2 ? ' voted' : ''}`}
				onClick={() => onSelectButton(2)}
				size={'medium'}>
				<StyledIcon
					className={selectedIcon === 2 ? animationClassName : ''}
					icon={selectedIcon === 2 ? ThumbsUpIcon : ThumbsUpOutlineIcon}
					alt={'thumbs up'}
					iconType={'systemIconDefault'}
					viewBox={'0 0 20 20'}
					size={'small'}
				/>
			</StyledIconButton>
		</Fragment>
	);

	return (
		<StyledHelpfulnessSurveyRoot className={className} data-testing-label={'helpfulness-survey'}>
			<StyledHelpfulnessSurveyText>{t('helpfulnessSurveyText', 'Is this helpful?')}</StyledHelpfulnessSurveyText>
			{thumbs}
		</StyledHelpfulnessSurveyRoot>
	);
};
