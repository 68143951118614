import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { LocalizationProvider, DatePicker as MUIDatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { endOfMonth, format } from 'date-fns';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { constVoid, Effect } from '../../utils/function.utils';
import i18n from '../../utils/i18.utils';
import {
	dateToFormattedDateString,
	getDatePickerDateStringFormatForCurrentLocale,
	getTimeLocale,
} from '../../utils/time.utils';
import { Nullable } from '../../utils/types.utils';
import { CustomCalendarHeader } from './date-picker-header/date-picker-header.component';
import { StyledCalendarLoading, StyledErrorBox, StyledNoAvailableSlotsLabel } from './date-picker.styles';

interface DatePickerProps {
	selectedDate: Nullable<Date>;
	availableDays: string[];
	noAvailableDaysLabel?: string;
	isDisabled: boolean;
	isNextMonthAvailable: boolean;
	onDateChage: Effect<Date>;
	onMonthChange(date: Date): Promise<void>;
	language?: string;
	label?: string;
}

interface CustomPickersDayProps extends PickersDayProps<Date> {
	'data-testing-label'?: string;
}
export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
	(
		{
			selectedDate,
			availableDays,
			noAvailableDaysLabel,
			isDisabled,
			language = 'en',
			label,
			isNextMonthAvailable,
			onDateChage,
			onMonthChange,
		},
		ref,
	) => {
		const [isMonthLoading, setIsMonthLoadingState] = useState(false);
		const [error, setError] = useState<string | null>(null);

		const { t } = useTranslation();

		const handleDateChange = (value: Nullable<Date>) => {
			if (value && !isNaN(value.getTime()) && availableDays.includes(format(value, 'dd'))) {
				onDateChage(value);
			}
		};

		const handleMonthChange = (date: Nullable<Date>): Promise<any> => {
			setIsMonthLoadingState(true);
			return date ? onMonthChange(date) : new Promise(constVoid);
		};

		const isDayDisabled = (day: Date) =>
			!availableDays.includes(format(day, 'dd')) && day.getTime() >= currentDate.getTime();

		const handleError = (error: React.ReactNode) => {
			if (error === 'disablePast') {
				setError(t('datePickerMinDateErrorLabel', 'Selected date can’t be before today'));
			} else {
				setError(null);
			}
		};

		useEffect(() => {
			setIsMonthLoadingState(false);
		}, [availableDays]);

		const emptyLabel = t('date', 'Date');
		const currentDate = new Date();
		const lastDateOfCurrentMonth = endOfMonth(currentDate);

		const dateFormatted = useMemo(getDatePickerDateStringFormatForCurrentLocale, [i18n.language]);
		const CustomPickersDay: React.FC<CustomPickersDayProps> = (props) => (
			<PickersDay
				data-testing-label={`calendar-day-${props.disabled ? 'disabled' : 'available'}-${dateToFormattedDateString(props.day)}`}
				{...props}
			/>
		);

		return (
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getTimeLocale(language)}>
				<MUIDatePicker
					data-testing-label={'date-picker-root'}
					label={selectedDate ? label : undefined}
					sx={{ width: '100%' }}
					disabled={isDisabled}
					showDaysOutsideCurrentMonth={false}
					disableHighlightToday
					maxDate={!isNextMonthAvailable ? lastDateOfCurrentMonth : undefined}
					disablePast
					onError={handleError}
					value={selectedDate}
					ref={ref}
					slotProps={{
						openPickerButton: {
							...{ 'data-testing-label': 'date-picker-toggle-button' },
							sx: ({ palette: { colorScheme, borderRadius } }) => ({
								'&:hover': {
									background: colorScheme.colorVariables.colorPrimarySoft,
									borderRadius: `${borderRadius.commonRadius}px`,
								},
								'&:focus-visible': { borderRadius: `${borderRadius.commonRadius}px` },
							}),
						},
						openPickerIcon: {
							sx: {
								fontSize: 32,
							},
						},
						popper: { placement: 'bottom' },
						textField: {
							placeholder: emptyLabel,
							InputProps: {
								sx: ({ typography, palette }) => ({
									...typography.smallViewPort.body,
									color: palette.colorScheme.colorVariables.colorText,
									border: `1px solid ${palette.colorScheme.colorVariables.colorBorder}`,
									'& input': {
										lineHeight: '1.5',
										padding: ({ palette: { sizes } }) =>
											selectedDate && label
												? `${sizes.size_4}px ${sizes.size_5}px 10px 20px`
												: `21px ${sizes.size_5}px 21px 20px`,
									},
									'&.Mui-disabled': {
										color: palette.colorScheme.colorVariables.colorNote,
										backgroundColor: palette.colorScheme.colorVariables.colorInactive,
										cursor: 'auto',
										'-webkit-text-fill-color': palette.colorScheme.colorVariables.colorNote,
									},
								}),
							},
							InputLabelProps: {
								sx: { left: 20, top: 12 },
							},
							inputProps: {
								'data-testing-label': 'date-picker-root',
							},
						},
					}}
					renderLoading={() => <StyledCalendarLoading style={{ width: '100%', height: '100%' }} />}
					slots={{
						openPickerIcon: CalendarMonthRoundedIcon,
						day: CustomPickersDay,
						calendarHeader: (props) => (
							<CustomCalendarHeader
								{...props}
								language={language}
								isNextMonthAvailable={isNextMonthAvailable}
							/>
						),
					}}
					format={dateFormatted}
					loading={isMonthLoading}
					onMonthChange={handleMonthChange}
					onChange={handleDateChange}
					shouldDisableDate={isDayDisabled}
				/>
				{!isDisabled && !availableDays.length && noAvailableDaysLabel && (
					<StyledNoAvailableSlotsLabel data-testing-label={'date-picker-no-available-days-label'}>
						{noAvailableDaysLabel}
					</StyledNoAvailableSlotsLabel>
				)}
				{error && <StyledErrorBox>{error}</StyledErrorBox>}
			</LocalizationProvider>
		);
	},
);
