import { Box, Paper, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { heartbeatAnimation } from '../../utils/mui-animations.utils';
import { Info } from '../info/info.component';

const getAdjustedRadius = (radius: number): number => ([0, 2].includes(radius) ? radius : radius - 4);

export const StyledTimeInputWrapper = styled(Box)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		display: 'flex',
		flexDirection: 'row',
		columnGap: sizes.size_2,
	}),
);

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({
	theme: {
		palette: { borderRadius },
	},
}) => {
	return {
		margin: 3,
		borderRadius: getAdjustedRadius(borderRadius.commonRadius),
		backgroundColor: 'hsla(0, 0%, 98%, 1)',
		border: 0,
		display: 'flex',
		justifyContent: 'space-between',
	};
});

export const StyledBox = styled(Box)({
	alignItems: 'center',
	columnGap: 6,
	display: 'flex',
	width: '100%',
});

export const StyledPaper = styled(Paper)(
	({
		theme: {
			palette: {
				borderRadius,
				colorScheme: { colorVariables },
			},
		},
	}) => ({
		border: `1px solid ${colorVariables.colorBorder}`,
		transition: 'all 0.8s',
		outline: '2px solid transparent',

		backgroundColor: 'hsla(0, 0%, 98%, 1)',
		flexWrap: 'wrap',
		display: 'flex',
		borderRadius: borderRadius.commonRadius,

		boxShadow: 'none',

		'&:hover': {
			transition: 'all 0.1s',
			borderColor: colorVariables.colorNote,
		},

		'&:focus-within': {
			border: '1px solid transparent',
			outline: `2px solid ${colorVariables.colorPrimary}`,
		},
	}),
);

export const StyledToggleButton = styled(ToggleButton)(({
	theme: {
		palette: {
			sizes,
			colorScheme: { colorVariables },
			borderRadius,
		},
	},
}) => {
	return {
		height: '27px',
		width: sizes.size_7,
		textTransform: 'lowercase',
		border: 0,
		backgroundColor: 'white',
		borderRadius: 0,

		'&:hover': { backgroundColor: colorVariables.colorPrimarySoft, filter: 'brightness(96%)' },
		'&:first-of-type': {
			borderTopLeftRadius: getAdjustedRadius(borderRadius.commonRadius),
			borderTopRightRadius: getAdjustedRadius(borderRadius.commonRadius),
		},
		'&:last-of-type': {
			borderBottomRightRadius: getAdjustedRadius(borderRadius.commonRadius),
			borderBottomLeftRadius: getAdjustedRadius(borderRadius.commonRadius),
		},

		'&.Mui-selected': {
			backgroundColor: colorVariables.colorPrimarySoft,

			'&:hover': {
				backgroundColor: colorVariables.colorPrimarySoft,
				filter: 'brightness(96%)',
			},
		},
		'&.Mui-disabled': {
			border: 0,
		},
	};
});

export const StyledDateErrorMessage = styled(Info)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		marginTop: sizes.size_2,
		'& > svg': {
			animation: `${heartbeatAnimation} 3s linear infinite`,
		},
	}),
);
