import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ChangeEvent, createRef, FC, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isMobileDeviceByTouchPoints } from '../../utils/device.utils';
import { Effect, Lazy } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { Icon } from '../icon/icon.component';
import { GalleryIcon } from '../icons/gallery.icon';
import { NoImageIcon } from '../icons/no-image.icon';
import { PhotoCameraIcon } from '../icons/photo-camera.icon';
import { Info } from '../info/info.component';
import {
	ActionButton,
	BlurredLayer,
	CameraButton,
	ContinueButton,
	Error,
	ImagePreviewStyled,
	LinearProgressStyled,
	NoPreviewRoot,
	PreviewWrapper,
	Progress,
	ProgressLabel,
	ProgressLabelsContainer,
	RemoveUploadedImageButton,
	StyledInput,
	TakePictureButton,
	UploaderRoot,
	Video,
	VideoPreview,
} from './image-uploader.styled';

const PREVIEW_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const isImagePreviewUnavailable = (file: File): boolean =>
	!isMobileDeviceByTouchPoints() && !PREVIEW_IMAGE_FORMATS.includes(file.type);

const stopVideoRecording = (video: Nullable<HTMLVideoElement>): void => {
	if (video) {
		// Using any because of the Typescript error: Property 'getVideoTracks' does not exist on type 'MediaProvider'.
		(video.srcObject as any)?.getVideoTracks().forEach((track: any) => track.stop());
	}
};
export interface ImageUploaderProps {
	uploadError?: Nullable<string>;
	progress?: Nullable<number>;
	isMultiFiles?: boolean;
	isImageCaptureAvailableOnDesktop?: boolean;
	isDisabled?: boolean;
	onUpload: Effect<File[]>;
	onCancel: Lazy<void>;
	onProgressReset: Lazy<void>;
	onUploadErrorClear?: Lazy<void>;
}

interface ImagePreview {
	image: string;
	isAvailable: boolean;
}

export const ImageUploader: FC<ImageUploaderProps> = ({
	uploadError,
	isMultiFiles,
	progress,
	isImageCaptureAvailableOnDesktop = true,
	isDisabled,
	onUpload,
	onCancel,
	onProgressReset,
	onUploadErrorClear,
}): JSX.Element => {
	const { t } = useTranslation();
	const isMobileDevice = isMobileDeviceByTouchPoints();
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [previewImages, setPreviewImages] = useState<ImagePreview[]>([]);
	const [internalError, setInternalError] = useState<string>();
	const [isVideoShown, setIsVideoShown] = useState(false);

	const videoRef = useRef<HTMLVideoElement | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	const inputRef = createRef<HTMLInputElement>();
	const inputTakePhotoRef = createRef<HTMLInputElement>();

	// =============================This logic is only for the desktop take picture ================================
	const handleCapture = async () => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
				if (videoRef.current) {
					videoRef.current.srcObject = stream;
					videoRef.current.play();
					setIsVideoShown(true);
				}
			} catch (error) {
				alert(`Error accessing the camera: ${error}`);
			}
		}
	};

	const NoImagePreview = () => (
		<NoPreviewRoot data-testing-label={'image-uploader-preview-unavailable'}>
			<Icon icon={NoImageIcon} iconType={'systemIconDefault'} />
			<p>{t('imageUploaderNoPreview')}</p>
		</NoPreviewRoot>
	);

	const handleCaptureImage = () => {
		const video = videoRef.current;
		const canvas = canvasRef.current;

		if (video && canvas) {
			const context = canvas.getContext('2d');
			context?.drawImage(video, 0, 0, canvas.width, canvas.height);

			canvas.toBlob((blob) => {
				if (blob) {
					const image = URL.createObjectURL(blob);
					const file = new File([blob], 'taked_picture.png', { type: 'image/png' });
					setSelectedFiles([file]);
					setPreviewImages([{ image, isAvailable: true }]);
				}
			});

			stopVideoRecording(video);
			setIsVideoShown(false);
		}
	};
	// ===============================================================================

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
		onUploadErrorClear && onUploadErrorClear();
		onProgressReset();
		const files = Array.from(e.target.files || []) as File[];

		// Validate number of files
		if (isMultiFiles && files.length + selectedFiles.length > 4) {
			return setInternalError(t('imageUploadFileNumberError'));
		}

		// Validate file size
		const maxSize = 10 * 1024 * 1024; // 10MB
		let totalSize = selectedFiles.reduce((total, file) => total + file.size, 0);
		const validFiles: File[] = [];

		for (const file of files) {
			if (file.size + totalSize <= maxSize) {
				totalSize += file.size;
				validFiles.push(file);
			} else {
				return setInternalError(t('imageUploadFileSizeError'));
			}
		}
		setInternalError('');
		setSelectedFiles([...selectedFiles, ...validFiles]);
		const filePreviews = validFiles.map((file) => ({
			image: URL.createObjectURL(file),
			isAvailable: !isImagePreviewUnavailable(file),
		}));

		setPreviewImages([...previewImages, ...filePreviews]);
	};

	const handleCancel = (): void => {
		if (progress && onCancel) {
			onCancel();
		} else {
			onUploadErrorClear && onUploadErrorClear();
			setSelectedFiles([]);
			setPreviewImages([]);
			if (inputRef.current) {
				inputRef.current.value = '';
			}
		}
	};

	const handleSingleRemove = (imageIndex: number): void => {
		const updatedSelectedFiles = selectedFiles.filter((file, index) => index !== imageIndex);
		const updatedPreviews = previewImages.filter((file, index) => index !== imageIndex);
		setSelectedFiles(updatedSelectedFiles);
		setPreviewImages(updatedPreviews);
	};

	const renderProgress = (progress: number): JSX.Element | null =>
		progress ? (
			<Progress data-testing-label={'image-uploader-progress'}>
				<ProgressLabelsContainer>
					<ProgressLabel>{t('imageUploaderProgressLabel')}</ProgressLabel>
					<ProgressLabel>{progress}%</ProgressLabel>
				</ProgressLabelsContainer>
				<LinearProgressStyled variant={'determinate'} value={progress} />
			</Progress>
		) : null;

	const buildImagePreview = (previews: ImagePreview[], progress?: Nullable<number>): JSX.Element | JSX.Element[] => {
		if (isMultiFiles) {
			// TODO: extend with multi-select functionality
			return previews.map((preview, index) => (
				<PreviewWrapper key={preview.image}>
					{preview.isAvailable ? (
						<ImagePreviewStyled
							progress={Boolean(progress)}
							src={preview.image}
							alt={`preview_${index}`}
							data-testing-label={`image-uploader-preview-image-${index}`}
						/>
					) : (
						<NoImagePreview />
					)}
					<RemoveUploadedImageButton
						color={'primary'}
						onClick={() => handleSingleRemove(index)}
						data-testing-label={`image-uploader-remove-image-btn-${index}`}
						aria-label={'remove image'}
						disabled={isDisabled}>
						<Icon icon={CloseRoundedIcon} size={'medium'} alt={'remove'} />
					</RemoveUploadedImageButton>
					{progress && renderProgress(progress)}
				</PreviewWrapper>
			));
		} else {
			return (
				<PreviewWrapper>
					{progress && <BlurredLayer />}
					{previews[0].isAvailable ? (
						<ImagePreviewStyled
							progress={Boolean(progress)}
							src={previews[0].image}
							alt={'preview'}
							data-testing-label={'image-uploader-preview-image'}
						/>
					) : (
						<NoImagePreview />
					)}
					{progress && renderProgress(progress)}
				</PreviewWrapper>
			);
		}
	};

	const cancelCapturingVideo = () => {
		stopVideoRecording(videoRef.current);
		setIsVideoShown(false);
	};

	const actionButtonConfig = () => {
		if (progress) {
			return {
				labelKey: 'cancel',
				icon: <CancelRoundedIcon />,
			};
		}
		return selectedFiles.length
			? {
					labelKey: 'imageUploaderDiscard',
				}
			: {
					labelKey: 'imageUploaderChoosePhotoLabel',
					icon: <GalleryIcon />,
				};
	};

	const error = uploadError || internalError;
	const secondaryButtonConfig = actionButtonConfig();
	const errorComponent = error ? (
		<Error status={'Alert'} label={error} isSimple dataTestingLabel={'image-uploader-error-label'} />
	) : null;

	const shouldShowErrorOnTop = isImageCaptureAvailableOnDesktop && previewImages.length === 0;
	return (
		<Fragment>
			{isImageCaptureAvailableOnDesktop && !isMobileDevice && (
				<div>
					{shouldShowErrorOnTop && errorComponent}
					<VideoPreview>
						<Video
							ref={videoRef}
							style={{ display: isVideoShown ? 'block' : 'none' }}
							data-testing-label={'image-uploader-video'}>
							<track kind={'captions'} />
						</Video>
						<canvas ref={canvasRef} style={{ display: 'none' }} width={640} height={480} />
						{isVideoShown && (
							<Fragment>
								<TakePictureButton
									isFullWidth
									color={'primary'}
									onClick={handleCaptureImage}
									data-testing-label={'image-uploader-take-photo'}>
									{t('takePhoto')}
								</TakePictureButton>
								<TakePictureButton
									isFullWidth
									color={'secondary'}
									onClick={cancelCapturingVideo}
									data-testing-label={'image-uploader-cancel-take-photo'}>
									{t('cancel')}
								</TakePictureButton>
							</Fragment>
						)}
					</VideoPreview>
					{!navigator?.mediaDevices?.getUserMedia && (
						<Info
							status={'Alert'}
							label={'Sorry, the camera is not supported on your device.'}
							dataTestingLabel={'image-uploader-no-camera-support'}
						/>
					)}
					{!selectedFiles.length && !isVideoShown && (
						<ActionButton
							label={t('takePhoto')}
							icon={<PhotoCameraIcon />}
							isDisabled={isDisabled}
							dataTestingLabel={'image-uploader-show-capture-image'}
							onClick={handleCapture}
						/>
					)}
				</div>
			)}
			<UploaderRoot data-testing-label={'image-uploader'}>
				{previewImages.length > 0 && buildImagePreview(previewImages, progress)}
				{!shouldShowErrorOnTop && errorComponent}
				<StyledInput
					ref={inputRef}
					type={'file'}
					onChange={handleFileChange}
					accept={'image/jpg, image/jpeg, image/png, image/heic'}
					id={'file-upload-mobile'}
					data-testing-label={'file-upload-mobile'}
					multiple={isMultiFiles}
				/>
				<StyledInput
					ref={inputTakePhotoRef}
					type={'file'}
					onChange={handleFileChange}
					accept={'image/jpg, image/jpeg, image/png, image/heic'}
					id={'file-upload'}
					data-testing-label={'file-upload'}
					capture={'environment'}
					multiple={isMultiFiles}
				/>

				{isMobileDevice && !selectedFiles.length && !isVideoShown && (
					<ActionButton
						label={t('takePhoto')}
						icon={<PhotoCameraIcon />}
						onClick={() => (previewImages.length ? handleCancel() : inputTakePhotoRef.current?.click())}
						dataTestingLabel={'image-uploader-mobile-camera-btn'}
						isDisabled={isDisabled}
					/>
				)}
				{!isVideoShown && (
					<Fragment>
						<CameraButton
							label={t(secondaryButtonConfig.labelKey)}
							icon={secondaryButtonConfig.icon}
							hasPreviewImages={previewImages.length > 0}
							isProgressing={Boolean(progress)}
							isDisabled={progress ? false : isDisabled}
							onClick={() => (previewImages.length ? handleCancel() : inputRef.current?.click())}
							dataTestingLabel={`image-uploader-${t(secondaryButtonConfig.labelKey)}-btn`}
						/>
						<div>
							<ContinueButton
								isFullWidth
								color={'primary'}
								disabled={selectedFiles.length === 0 || progress !== null || isDisabled}
								onClick={() => onUpload(selectedFiles)}
								data-testing-label={'image-uploader-continue-btn'}>
								{t('continue')}
							</ContinueButton>
						</div>
					</Fragment>
				)}
			</UploaderRoot>
		</Fragment>
	);
};
