import { InputLabel, OutlinedInput, styled } from '@mui/material';

import { bounceHor } from '../../utils/mui-animations.utils';
import { shouldForwardProps } from '../../utils/mui-styled.utils';

export const StyledInputLabel = styled(InputLabel)({
	top: 8,
	left: 16,
});

export const StyledOutlinedInput = styled(OutlinedInput, {
	shouldForwardProp: shouldForwardProps(['withLabel', 'error']),
})<{ withLabel?: boolean; error?: boolean }>(({ theme, withLabel, error }) => ({
	border: `1px solid ${theme.palette.colorScheme.colorVariables.colorBorder}`,
	transition: 'all 0.8s',
	outline: '2px solid transparent',

	'&:hover': {
		transition: 'all 0.1s',
		borderColor: theme.palette.colorScheme.colorVariables.colorNote,
	},

	'&.Mui-focused': {
		transition: 'all 0.1s',
	},

	'&:has(input:disabled)': {
		borderColor: theme.palette.colorScheme.colorVariables.colorBorder,
	},

	...(error && {
		border: `1px solid ${theme.palette.colorScheme.colorVariables.colorSysTextAlert}`,
		'&:hover': {
			border: `1px solid ${theme.palette.colorScheme.colorVariables.colorSysTextAlert}`,
		},
		animation: `${bounceHor} 0.35s`,
	}),

	'& legend': {
		visibility: 'visible',
		letterSpacing: 0.2,
		fontSize: 12,
		maxWidth: 'initial',
		position: 'absolute',
		top: 10,
		left: 11,
	},

	'& fieldset': {
		top: 0,
	},
	'& input': {
		padding: withLabel ? '28px 16px 10px' : '16px 16px 10px',
	},
}));
