import { styled } from '@mui/material';

import { Button } from '../button/button.component';

export const StyledResponseWrapperForm = styled('form')({
	display: 'flex',
	flexDirection: 'column',
});

export const StyledResponseWrapperButton = styled(Button)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		marginTop: sizes.size_2,
	}),
);
