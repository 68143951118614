import { styled } from '@mui/material';

import { Chip } from '../../ui-kit/chip/chip.component';

export const Container = styled('div')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		padding: `0 ${sizes.size_1}%`,
	}),
);

export const ResponsesContainer = styled('div')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		padding: `${sizes.size_1} 0 ${sizes.size_2}`,
	}),
);

export const QuickResponseButton = styled(Chip)({
	width: '100%',
});
