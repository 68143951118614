import { Button, MenuItem, styled } from '@mui/material';

export const StyledUnitSelectorMenuItem = styled(MenuItem)(
	({
		theme: {
			palette: { colorScheme },
		},
	}) => ({
		marginInline: 4,
		'&:hover': {
			backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
		},
		'&.Mui-selected': {
			backgroundColor: 'hsl(216, 100%, 86%)',
		},
		'&.Mui-selected:hover': {
			backgroundColor: 'hsl(216, 100%, 86%)',
		},
	}),
);

export const StyledUnitSelectorMenuButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'isFocused',
})<{ isFocused: boolean }>(({ theme, isFocused }) => ({
	display: 'flex',
	color: theme.palette.colorScheme.colorVariables.colorSysTextDefault,
	minHeight: 44,
	minWidth: 70,
	alignItems: 'center',
	borderRadius: theme.palette.sizes.sizeHalf,
	background: theme.palette.colorScheme.colorVariables.colorSysBgDefault,
	boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.10)',
	textTransform: 'none',
	font: 'inherit',
	'&:hover': {
		background: theme.palette.colorScheme.colorVariables.colorSysBgDefault,
		boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.10)',
		filter: 'none',
	},
	...(isFocused && {
		color: 'hsl(0, 0%, 3%)',
	}),
}));

export const StyledUnitSelectorLabel = styled('span', {
	shouldForwardProp: (prop) => prop !== 'isFocused',
})<{ isFocused: boolean }>(({ theme, isFocused }) => ({
	color: theme.palette.colorScheme.colorVariables.colorSysTextDefault,
	...(isFocused && {
		color: 'hsl(0, 0%, 3%)',
	}),
}));
