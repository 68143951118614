import { FC } from 'react';

import { StyledFabricLink, StyledPoweredByLabel } from './powered-by-fabric.styles';

interface PoweredByFabricLabelProps {
	isWidgetOpen: boolean;
}

export const PoweredByFabricLabel: FC<PoweredByFabricLabelProps> = ({ isWidgetOpen }) => {
	return (
		<StyledPoweredByLabel isWidgetOpen={isWidgetOpen}>
			Powered by
			<StyledFabricLink href={'https://www.fabrichealth.com'} target={'_blank'} rel={'noreferrer'}>
				Fabric
			</StyledFabricLink>
		</StyledPoweredByLabel>
	);
};
