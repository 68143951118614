import { styled } from '@mui/material';

import { shouldForwardProps } from '../../utils/mui-styled.utils';
import { InfoColorSettings } from './info.component';

export const StyledInfoRoot = styled('div', {
	shouldForwardProp: shouldForwardProps(['infoColorSettings', 'isSimple']),
})<{ infoColorSettings: InfoColorSettings; isSimple: boolean }>(
	({
		theme: {
			palette: { borderRadius, sizes, boxShadow, colorScheme },
		},
		infoColorSettings,
		isSimple,
	}) => ({
		boxShadow: boxShadow.angle.large,
		padding: sizes.size_2,
		backgroundColor: infoColorSettings.bgColor,
		borderRadius: borderRadius.button,
		display: 'flex',
		alignItems: 'center',
		border: `1px solid ${colorScheme.colorVariables.colorMediumShadow}`,
		...(isSimple && {
			boxShadow: 'none',
			border: 'none',
			background: 'white !important',
			padding: 0,
		}),
	}),
);

export const StyledInfoIconWrapper = styled('div', { shouldForwardProp: shouldForwardProps(['infoColorSettings']) })<{
	infoColorSettings: InfoColorSettings;
}>(
	({
		theme: {
			palette: { sizes },
		},
		infoColorSettings,
	}) => ({
		color: infoColorSettings.iconColor,
		marginRight: sizes.size_1,
		width: sizes.size_3,
		height: sizes.size_3,
	}),
);

export const StyledInfoLabel = styled('span', { shouldForwardProp: shouldForwardProps(['infoColorSettings']) })<{
	infoColorSettings: InfoColorSettings;
}>(
	({
		theme: {
			typography: { smallViewPort },
		},
		infoColorSettings,
	}) => ({
		...smallViewPort.typeNoteParagraph,
		color: infoColorSettings.textColor,
		marginBottom: 0,
	}),
);
