import { styled } from '@mui/material';

import { CARD_MAX_WIDTH } from '../../../utils/sizes.utils';
import { IconButton } from '../../icon-button/icon-button.component';

export const StyledSatisfactionSurveyContainer = styled('div')(
	({
		theme: {
			palette: { borderRadius, sizes, colorScheme },
		},
	}) => ({
		borderRadius: borderRadius.commonRadius,
		height: sizes.size_13,
		background: colorScheme.colorVariables.colorSysBgDefault,
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		maxWidth: CARD_MAX_WIDTH,
	}),
);

export const StyledSatisfactionSurveyIconButton = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== 'numberOfButtons',
})<{ numberOfButtons: number }>(({ theme, numberOfButtons }) => ({
	width: `calc(100% / (${numberOfButtons} + 1))`,
	'&:hover': {
		backgroundColor: theme.palette.colorScheme.colorVariables.colorSysBgDefault,
		filter: 'brightness(96%)',
		transition: 'all 0.1s',
	},
	'&:focus-visible': {
		outlineOffset: 0,
	},
}));
