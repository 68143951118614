import { keyframes, styled } from '@mui/material';

import { IconButton } from '../../icon-button/icon-button.component';
import { Icon } from '../../icon/icon.component';

const thumbsDownAnimation = keyframes`
  0% {
    transform: rotate(0deg) translateY(0);
  }
  30% {
    transform: rotate(5deg) translateY(-2px);
  }
  55% {
    transform: rotate(-15deg) translateY(6px);
  }
  70% {
    transform: rotate(12deg) translateY(-5px);
  }
  100% {
    transform: rotate(-2deg) translateY(1px);
  }
`;

const thumbsUpAnimation = keyframes`
  0% {
    transform: rotate(0deg) translateY(0);
  }
  40% {
    transform: rotate(-10deg) translateY(-4px);
  }
  55% {
    transform: rotate(20deg) translateY(6px);
  }
  70% {
    transform: rotate(-15deg) translateY(-4px);
  }
  100% {
    transform: rotate(5deg) translateY(2px);
  }
`;

export const StyledHelpfulnessSurveyRoot = styled('div')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		alignItems: 'center',
		display: 'flex',
		height: sizes.size_5,
	}),
);

export const StyledHelpfulnessSurveyText = styled('h4')(
	({
		theme: {
			palette: { sizes },
			typography: { smallViewPort },
		},
	}) => ({
		paddingRight: sizes.size_2,
		...smallViewPort.typeSysText2,
		margin: 0,
	}),
);

export const StyledIconButton = styled(IconButton)(
	({
		theme: {
			palette: { sizes, colorScheme },
		},
	}) => ({
		width: sizes.size_5,
		height: sizes.size_5,
		'&:not(:last-child)': {
			marginRight: sizes.sizeHalf,
		},
		'&:hover': {
			filter: 'unset',
			transition: 'unset',
			background: colorScheme.colorVariables.colorSysBgDefault,
		},
	}),
);

export const StyledIcon = styled(Icon)({
	size: 'small',
	'&.fabric-fd-thumbsDown': {
		animation: `${thumbsDownAnimation} 1s cubic-bezier(0.65, 0, 0.35, 1)`,
	},
	'&.fabric-fd-thumbsUp': {
		animation: `${thumbsUpAnimation} 1s cubic-bezier(0.65, 0, 0.35, 1)`,
	},
});
