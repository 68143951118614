import { FC, FormEvent, ReactNode } from 'react';

import { Lazy } from '../../utils/function.utils';
import { StyledResponseWrapperButton, StyledResponseWrapperForm } from './response-wrapper.styles';

interface ResponseWrapperProps {
	children: ReactNode;
	isDisabled: boolean;
	buttonLabel: string;
	className?: string;
	dataTestingLabel?: string;
	onSubmit: Lazy<void>;
}

export const ResponseWrapper: FC<ResponseWrapperProps> = ({
	className,
	isDisabled,
	buttonLabel,
	dataTestingLabel,
	children,
	onSubmit,
}) => {
	const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		onSubmit();
	};

	return (
		<StyledResponseWrapperForm
			onSubmit={handleFormSubmit}
			className={className}
			data-testing-label={dataTestingLabel}>
			{children}
			<StyledResponseWrapperButton
				type={'submit'}
				color={'primary'}
				disabled={isDisabled}
				data-testing-label={'response-type-send-button'}>
				{buttonLabel}
			</StyledResponseWrapperButton>
		</StyledResponseWrapperForm>
	);
};
