import { FC, memo } from 'react';

import { StyledVideo, VideoRoot } from './video.styled';

interface VideoProps {
	src: string;
}

export const Video: FC<VideoProps> = memo(({ src }) => {
	return (
		<VideoRoot>
			<StyledVideo controls preload={'metadata'} controlsList={'nodownload noremoteplayback'}>
				<track kind={'captions'} />
				<source src={src} type={'video/mp4'} />
			</StyledVideo>
		</VideoRoot>
	);
});
