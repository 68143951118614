import { styled } from '@mui/material';

import { Avatar } from '../../avatar/avatar.component';
import { IconButton } from '../../icon-button/icon-button.component';
import { HelpfulnessSurvey } from '../helpfulness-survey/helpfulness-survey.component';

export const StyledIconButton = styled(IconButton)(
	({
		theme: {
			palette: {
				sizes,
				colorScheme: { colorVariables },
			},
		},
	}) => ({
		width: sizes.size_4,
		height: sizes.size_4,
		backgroundColor: colorVariables.colorSysBgDefault,
		marginLeft: sizes.size_1 + sizes.sizeHalf,
		'&:hover': {
			backgroundImage: 'none',
			backgroundColor: 'hsla(0, 0%, 20%, 0.08)',
		},
	}),
);

export const StyledHelpfulnessSurveyExternalAvatar = styled(Avatar)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		width: sizes.size_5,
		height: sizes.size_5,
		'& svg': {
			width: sizes.size_3 + sizes.sizeQuarter,
			height: sizes.size_3 + sizes.sizeQuarter,
		},
	}),
);

export const StyledHelpfulnessSurveyExternalRoot = styled('div', {
	shouldForwardProp: (prop) => prop !== 'hasWidget' && prop !== 'timeout',
})<{ hasWidget: boolean; timeout: { enter: number; exit: number } }>(
	({
		theme: {
			palette: { borderRadius, sizes, colorScheme, boxShadow },
		},
		hasWidget,
		timeout,
	}) => ({
		backgroundColor: 'white',
		zIndex: 100000001,
		right: sizes.size_2,
		position: 'fixed',
		borderRadius: borderRadius.widget,
		boxShadow: boxShadow.angle.large,
		alignItems: 'center',
		display: 'flex',
		width: 'fit-content',
		padding: `${sizes.size_1}px ${sizes.size_3}px ${sizes.size_1}px ${sizes.size_1}px`,
		bottom: hasWidget ? colorScheme.chatWindowBottomPosition : sizes.size_2,
		opacity: 1,
		'&.fabric-fd-enter': {
			opacity: 0,
			bottom: 0,
		},
		'&.fabric-fd-enter-active': {
			bottom: hasWidget ? colorScheme.chatWindowBottomPosition : sizes.size_2,
			opacity: 1,
			transition: `all ${timeout.enter}ms `,
		},
		'&.fabric-fd-exit-active': {
			opacity: 0,
			bottom: 0,
			transition: `all ${timeout.exit}ms `,
		},
	}),
);

export const StyledHelpfulnessSurveyExternalContent = styled(HelpfulnessSurvey)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		marginLeft: sizes.size_2,
	}),
);
