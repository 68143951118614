import classNames from 'classnames';
import { FC, Fragment, useContext } from 'react';

import { SessionContext } from '../../../../context/session.context';
import { Button } from '../../../../ui-kit/button/button.component';
import { Lazy } from '../../../../utils/function.utils';
import { useLiveChatGreetingCardStyles } from './greeting-card.styles';

type LiveChatGreetingCardMode = 'greeting' | 'welcome';

export const fullPathImage = 'https://s3.amazonaws.com/assets.gyant.com/images/sds_liveChat_greeting.png';
export interface LiveChatGreetingCardProps {
	imageUrl?: string;
	title: string;
	onAction?: Lazy<void>;
	description?: string;
	className?: string;
	mode: LiveChatGreetingCardMode;
}

export const LiveChatGreetingCard: FC<LiveChatGreetingCardProps> = ({
	imageUrl,
	title,
	onAction,
	description,
	mode,
	className,
}) => {
	const {
		state: { isWidgetDisabled },
	} = useContext(SessionContext);

	const isGreetingMode = mode === 'greeting';
	const classes = useLiveChatGreetingCardStyles();

	const rootClassName = classNames(
		classes.root,
		isGreetingMode ? classes.greetingRoot : classes.welcomeRoot,
		className,
	);

	const renderedDescription = description && (
		<p className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
	);
	const renderGreetingMode = (): JSX.Element => (
		<Fragment>
			<Button
				color={'primary'}
				isFullWidth
				onClick={onAction}
				className={classes.actionButton}
				disabled={isWidgetDisabled}
				data-testing-label={'live-chat-greeting-button'}>
				{title}
			</Button>
			{renderedDescription}
		</Fragment>
	);
	const renderWelcomeMode = (): JSX.Element => (
		<Fragment>
			<h4 className={classes.welcomeTitle}> {title}</h4>
			{renderedDescription}
		</Fragment>
	);

	return (
		<div className={rootClassName} data-testing-label={'live-chat-greeting'}>
			<img src={imageUrl || fullPathImage} alt={'greeting live-chat'} />
			<div className={classes.content}>{isGreetingMode ? renderGreetingMode() : renderWelcomeMode()}</div>
		</div>
	);
};
