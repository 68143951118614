import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios';
import { Fragment, memo, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { ServicesContext } from '../../context/services.context';
import { SessionContext } from '../../context/session.context';
import { GenericQuickResponse, QuickResponseMessage } from '../../models/message.model';
import { ImageUploader } from '../../ui-kit/image-uploader/image-uploader.component';
import { EMPTY_QR_TEXT } from '../../utils/message-parser';
import { Nullable } from '../../utils/types.utils';
import { TextRenderer } from '../message-renderers/text-renderer/text-renderer.component';
import { Container, QuickResponseButton, ResponsesContainer } from './image-uploader.container.styles';

const CancelToken = axios.CancelToken;

interface ImageUploaderContainerProps {
	message: QuickResponseMessage;
}

export const ImageUploaderContainer = memo(({ message }: ImageUploaderContainerProps) => {
	const {
		state: { onSendMessage, isWidgetDisabled, sessionToken },
		onUpdate,
	} = useContext(SessionContext);

	const {
		webTrackerService: { sendEvent },
	} = useContext(ServicesContext);
	const { uploadImages } = useContext(APIServiceContext);

	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState<Nullable<number>>(null);
	const [uploadError, setUploadError] = useState<Nullable<string>>(null);

	const cancelTokenSourceRef = useRef<CancelTokenSource>();
	const cancelRequestRef = useRef(false);

	const onUploadProgress = (progressEvent: AxiosProgressEvent): void => {
		const { loaded, total } = progressEvent;
		const totalDivider = total || 1;
		const percent = Math.floor((loaded * 100) / totalDivider);
		setProgress(percent);
	};

	const handleUpload = async (files: File[]) => {
		setIsLoading(true);
		onUpdate({ isWidgetDisabled: true });
		const source = CancelToken.source();
		cancelTokenSourceRef.current = source;
		setUploadError(null);
		cancelRequestRef.current = false;
		const formData = new FormData();
		formData.append('sessionToken', sessionToken);
		if (message.nodeId) {
			formData.append('nodeId', message.nodeId);
		}
		files.forEach((file) => {
			formData.append('images', file);
		});

		try {
			await uploadImages({ formData, onUploadProgress, cancelToken: source.token });
			onSendMessage(message.responses[0].responseContext, message.responses[0].content);
			onUpdate({ isWidgetDisabled: false });
			setProgress(null);
		} catch (e) {
			setProgress(null);
			if (!cancelRequestRef.current) {
				setUploadError(t('imageUploadFileGenericError'));
			}
			setIsLoading(false);
			onUpdate({ isWidgetDisabled: false });
		}
	};

	const handleCancel = () => {
		cancelRequestRef.current = true;
		cancelTokenSourceRef.current?.cancel('Request is cancelled');
	};

	const handleResponseClick = (
		response: GenericQuickResponse,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	): void => {
		sendEvent('onUserSelect', e, response.responseContext);
		onSendMessage(response.responseContext, response.content);
	};

	const renderResponses = (responses: GenericQuickResponse[]) => {
		const visibleResponses = responses.slice(1);

		return (
			<ResponsesContainer>
				{visibleResponses.map((response) => {
					const content = <span dangerouslySetInnerHTML={{ __html: response.content }} />;
					return (
						<QuickResponseButton
							isDisabled={isWidgetDisabled || isLoading}
							dataTestingLabel={`image-uploader-quick-response-btn-${response.content}`}
							onClick={(e: any) => handleResponseClick(response, e)}
							aria-label={response.content}
							key={response.responseContext}
							label={content}
							isClickableOnce={response.type !== 'webUrlExternal'}
						/>
					);
				})}
			</ResponsesContainer>
		);
	};

	const isEmptyText = message.text === EMPTY_QR_TEXT;

	return (
		<Fragment>
			{!isEmptyText && (
				<TextRenderer
					message={message.text}
					flowStep={message.flowStep}
					isUserMessage={false}
					isUndoable={false}
				/>
			)}
			<Container>
				<ImageUploader
					isDisabled={isLoading || isWidgetDisabled}
					isImageCaptureAvailableOnDesktop
					onUpload={handleUpload}
					onCancel={handleCancel}
					onProgressReset={() => setProgress(null)}
					progress={progress}
					uploadError={uploadError}
					onUploadErrorClear={() => setUploadError(null)}
				/>
				{renderResponses(message.responses)}
			</Container>
		</Fragment>
	);
});
