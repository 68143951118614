import { keyframes } from '@mui/material/styles';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { CSSProperties, useEffect, useState } from 'react';

import { StartingAnimation } from '../context/api-service/api-service.model';

export type AnimatedElement = 'openToggle' | 'tooltip';

export type AnimationId =
	| 'gyant-animation-wobble'
	| 'gyant-animation-heartbeat'
	| 'gyant-animation-vibrate'
	| 'gyant-animation-jello'
	| 'gyant-animation-spin'
	| 'gyant-animation-appear'
	| 'gyant-animation-disappear'
	| 'gyant-animation-bounce-hor';

export type AnimationData = Record<string, Record<string, CSSProperties>>;

export interface StartingAnimationUI {
	animationName: AnimationId;
	animationDelay: number;
	animationDuration: number;
	animationIterationCount: number;
	element: 'openToggle' | 'tooltip';
}

export const ANIMATION_TIMEOUT = { enter: 350, exit: 350 };

//TODO : REMOVE AFTER FINISHING MIGRATION TO STYLED COMPONENTS
export const ANIMATIONS: Record<AnimationId, AnimationData> = {
	'gyant-animation-wobble': {
		'@keyframes gyant-animation-wobble': {
			'0%, 100%': {
				transform: 'translateX(0%)',
				transformOrigin: '50% 50%',
			},
			'15%': {
				transform: 'translateX(-30px) rotate(-6deg)',
			},
			'30%': {
				transform: 'translateX(15px) rotate(6deg)',
			},
			'45%': {
				transform: 'translateX(-15px) rotate(-3.6deg)',
			},
			'60%': {
				transform: 'translateX(9px) rotate(2.4deg)',
			},
			'75%': {
				transform: 'translateX(-6px) rotate(-1.2deg)',
			},
		},
	},
	'gyant-animation-heartbeat': {
		'@keyframes gyant-animation-heartbeat': {
			from: {
				transform: 'scale(1)',
				transformOrigin: 'center center',
				animationTimingFunction: 'ease-out',
			},
			'5%': {
				transform: 'scale(0.91)',
				animationTimingFunction: 'ease-in',
			},
			'10%': {
				transform: 'scale(0.98)',
				animationTimingFunction: 'ease-out',
			},
			'15%': {
				transform: 'scale(0.87)',
				animationTimingFunction: 'ease-in',
			},
			'20%': {
				transform: 'scale(1)',
				animationTimingFunction: 'ease-out',
			},
			'100%': {
				transform: 'scale(1)',
				transformOrigin: 'center center',
				animationTimingFunction: 'ease-out',
			},
		},
	},
	'gyant-animation-vibrate': {
		'@keyframes gyant-animation-vibrate': {
			'0%, 100%': {
				transform: 'translate(0)',
			},
			'3%': {
				transform: 'translate(-2px, 2px)',
			},
			'5%': {
				transform: 'translate(-2px, -2px)',
			},
			'8%': {
				transform: 'translate(2px, 2px)',
			},
			'10%': {
				transform: 'translate(2px, -2px)',
			},
			'13%': {
				transform: 'translate(0)',
			},
		},
	},
	'gyant-animation-jello': {
		'@keyframes gyant-animation-jello': {
			'0%, 100%': {
				transform: 'scale3d(1, 1, 1)',
			},
			'5%': {
				transform: 'scale3d(1.25, 0.75, 1)',
			},
			'10%': {
				transform: 'scale3d(0.75, 1.25, 1)',
			},
			'15%': {
				transform: 'scale3d(1.15, 0.85, 1)',
			},
			'20%': {
				transform: 'scale3d(0.95, 1.05, 1)',
			},
			'25%': {
				transform: 'scale3d(1.05, 0.95, 1)',
			},
		},
	},
	'gyant-animation-spin': {
		'@keyframes gyant-animation-spin': {
			'0%': { transform: 'rotate(0deg)' },
			'100%': { transform: 'rotate(360deg)' },
		},
	},
	'gyant-animation-appear': {
		'@keyframes gyant-animation-appear': {
			'0%': { opacity: 0 },
			'100%': { opacity: 1 },
		},
	},
	'gyant-animation-disappear': {
		'@keyframes gyant-animation-disappear': {
			'0%': { opacity: 1 },
			'100%': { opacity: 0 },
		},
	},

	'gyant-animation-bounce-hor': {
		'@keyframes gyant-animation-bounce-hor': {
			'0%': {
				transform: 'translateX(0px)',
				animationTimingFunction: 'ease-in',
			},
			'37%': {
				transform: 'translateX(5px)',
				animationTimingFunction: 'ease-out',
			},
			'55%': {
				transform: 'translateX(-5px)',
				animationTimingFunction: 'ease-in',
			},
			'73%': {
				transform: 'translateX(4px)',
				animationTimingFunction: 'ease-out',
			},
			'82%': {
				transform: 'translateX(-4px)',
				animationTimingFunction: 'ease-in',
			},
			'91%': {
				transform: 'translateX(2px)',
				animationTimingFunction: 'ease-out',
			},
			'96%': {
				transform: 'translateX(-2px)',
				animationTimingFunction: 'ease-in',
			},
			'100%': {
				transform: 'translateX(0px)',
				animationTimingFunction: 'ease-in',
			},
		},
	},
};

export const getAnimationById = (animationId: AnimationId): AnimationData => ANIMATIONS[animationId];

export const createAnimation = (animation: StartingAnimation): StartingAnimationUI => ({
	animationName: animation.animationID,
	animationDelay: animation.delayMs,
	animationDuration: animation.durationMs,
	animationIterationCount: animation.repeatCount,
	element: animation.element,
});

export const useRepetitiveAnimationStyles = (
	startingAnimation: StartingAnimationUI,
): (() => ClassNameMap<'animation'>) =>
	makeStyles(() => {
		const animationData = getAnimationById(startingAnimation.animationName);
		return {
			...animationData,
			animation: {
				animation: `$${startingAnimation.animationName}`,
				animationDelay: `${startingAnimation.animationDelay / 1000}s`,
				animationDuration: `${
					(startingAnimation.animationDelay + startingAnimation.animationDuration * 2) / 1000
				}s`,
				animationIterationCount: startingAnimation.animationIterationCount,
			},
		};
	});

export const useAnimationStyles = (startingAnimation: StartingAnimationUI): (() => ClassNameMap<'animation'>) =>
	makeStyles(() => {
		const animationData = getAnimationById(startingAnimation.animationName);
		return {
			...animationData,
			animation: {
				animation: `$${startingAnimation.animationName}`,
				animationDelay: `${startingAnimation.animationDelay / 1000}s`,
				animationDuration: `${startingAnimation.animationDuration / 1000}s`,
			},
		};
	});

export const useIncreasedCounterByAnimationConfig = (startingAnimation?: StartingAnimationUI): boolean => {
	const [isApplied, handleApplied] = useState(false);
	const [counter, handleCounter] = useState(0);

	if (startingAnimation) {
		useEffect(() => {
			if (counter < startingAnimation.animationIterationCount) {
				handleApplied(true);
				const timeOut = startingAnimation.animationDelay + startingAnimation.animationDuration + 1000;
				setTimeout(() => {
					handleCounter((c) => c + 1);
					handleApplied((a) => !a);
				}, timeOut);
			}
		}, [counter]);
	}
	return isApplied;
};

export const STYLED_ANIMATIONS = {
	'gyant-animation-wobble': keyframes`
    0%, 100% {
      transform: translateX(0%);
      transform-origin: 50% 50%;
    }
    15% {
      transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      transform: translateX(15px) rotate(6deg);
    }
    45% {
      transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      transform: translateX(-6px) rotate(-1.2deg);
    }
  `,
	'gyant-animation-heartbeat': keyframes`
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
    5% {
      transform: scale(0.91);
      animation-timing-function: ease-in;
    }
    10% {
      transform: scale(0.98);
      animation-timing-function: ease-out;
    }
    15% {
      transform: scale(0.87);
      animation-timing-function: ease-in;
    }
    20% {
      transform: scale(1);
      animation-timing-function: ease-out;
    }
    100% {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
  `,
	'gyant-animation-vibrate': keyframes`
    0%, 100% {
      transform: translate(0);
    }
    3% {
      transform: translate(-2px, 2px);
    }
    5% {
      transform: translate(-2px, -2px);
    }
    8% {
      transform: translate(2px, 2px);
    }
    10% {
      transform: translate(2px, -2px);
    }
    13% {
      transform: translate(0);
    }
  `,
	'gyant-animation-jello': keyframes`
    0%, 100% {
      transform: scale3d(1, 1, 1);
    }
    5% {
      transform: scale3d(1.25, 0.75, 1);
    }
    10% {
      transform: scale3d(0.75, 1.25, 1);
    }
    15% {
      transform: scale3d(1.15, 0.85, 1);
    }
    20% {
      transform: scale3d(0.95, 1.05, 1);
    }
    25% {
      transform: scale3d(1.05, 0.95, 1);
    }
  `,
	'gyant-animation-spin': keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `,
	'gyant-animation-appear': keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `,
	'gyant-animation-disappear': keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
  `,
	'gyant-animation-bounce-hor': keyframes`
    0% {
      transform: translateX(0px);
      animation-timing-function: ease-in;
    }
    37% {
      transform: translateX(5px);
      animation-timing-function: ease-out;
    }
    55% {
      transform: translateX(-5px);
      animation-timing-function: ease-in;
    }
    73% {
      transform: translateX(4px);
      animation-timing-function: ease-out;
    }
    82% {
      transform: translateX(-4px);
      animation-timing-function: ease-in;
    }
    91% {
      transform: translateX(2px);
      animation-timing-function: ease-out;
    }
    96% {
      transform: translateX(-2px);
      animation-timing-function: ease-in;
    }
    100% {
      transform: translateX(0px);
      animation-timing-function: ease-in;
    }
  `,
};
