import { keyframes, styled } from '@mui/material';

import { ApptMessageLoading } from '../../../../ui-kit/icons/appt-message-loading';

const blink = keyframes`
  50% {
    opacity: 0.2;
  }
`;

export const StyledLoadingSlotsRoot = styled('div')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		padding: `0 ${sizes.size_1}%`,
		flexDirection: 'column',
		justifyContent: 'center',
		display: 'flex',
	}),
);

export const StyledLoadingBotMessage = styled(ApptMessageLoading)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		width: 174,
		height: 21,
		animation: `${blink} 1s linear infinite`,
		marginBottom: sizes.size_2,
		marginTop: sizes.size_3,
		marginLeft: sizes.size_1,
	}),
);

export const StyledLoadingEmptySlot = styled('div')(
	({
		theme: {
			palette: { sizes, borderRadius, colorScheme },
		},
	}) => ({
		width: '100%',
		height: sizes.size_6,
		background: colorScheme.colorVariables.colorInactive,
		marginBottom: sizes.sizeHalf,
		borderRadius: borderRadius.commonRadius,
		animation: `${blink} 1s linear infinite`,
	}),
);

export const StyledLoadingMediumOpacitySlot = styled(StyledLoadingEmptySlot)({
	opacity: 0.65,
});

export const StyledLoadingLowOpacitySlot = styled(StyledLoadingEmptySlot)({
	opacity: 0.3,
});
