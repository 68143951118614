import { Collapse } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ANIMATION_TIMEOUT } from '../../utils/animations.utils';
import { Effect } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { Info } from '../info/info.component';
import { Input } from '../input/input.component';
import { ResponseWrapper } from '../response-wrapper/response-wrapper.component';
import { useMFAStyles } from './otp-input.styles';

interface OTPCredential extends Credential {
	code?: string;
}

export const OTPInput: React.FC<{
	length?: number;
	onSubmit: Effect<string>;
}> = ({ length = 6, onSubmit }) => {
	const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
	const [errorState, setErrorState] = useState<boolean[]>(Array(length).fill(false));
	const inputRefs = useRef<HTMLInputElement[]>([]);
	const classes = useMFAStyles();
	const { t } = useTranslation();

	useEffect(() => {
		if (inputRefs.current[0] && otp.every((value) => value === '')) {
			inputRefs.current[0]?.focus();
		}
	}, []);

	useEffect(() => {
		if ('OTPCredential' in window) {
			const newErrorState = [...errorState];
			const ac = new AbortController();

			navigator.credentials
				.get({
					otp: { transport: ['sms'] },
					signal: ac.signal,
				} as CredentialRequestOptions)
				.then((otpCredential: Nullable<OTPCredential>) => {
					if (otpCredential?.code) {
						const otpCode = otpCredential.code.slice(0, length).split('') || [];
						setOtp(otpCode);
						otpCode.forEach((digit: string, i: number) => {
							if (isNaN(Number(digit))) {
								newErrorState[i] = true;
								setErrorState(newErrorState);
								return;
							}
							if (inputRefs.current[i]) {
								inputRefs.current[i].value = digit;
							}
						});
					}
				})
				.catch((err: Error) => {
					console.log('WebOTP request failed: ', err);
				});

			return () => {
				ac.abort();
			};
		}
	}, [length]);

	const updateOtp = (newOtp: string[], index: number) => {
		const newErrorState = [...errorState];
		if (newOtp.length !== length || newOtp.some((digit) => isNaN(Number(digit)))) {
			newErrorState[index] = true;
			setErrorState(newErrorState);
			return;
		}
		setOtp(newOtp);
		newOtp.forEach((digit, i) => {
			if (inputRefs.current[i]) {
				inputRefs.current[i].value = digit;
			}
		});
		setErrorState(newOtp.map(() => false));
	};

	const handleChange = (value: string, index: number) => {
		const plainText = value.replace(/[^\x00-\x7F]/g, '').trim();
		const newErrorState = [...errorState];
		if ((value && isNaN(Number(value))) || plainText === '') {
			if (otp[index]) {
				return;
			}
			newErrorState[index] = true;
			setErrorState(newErrorState);
			return;
		}
		const areAllEmpty = otp.every((char) => char == '');
		if (value.length > 1 && areAllEmpty) {
			updateOtp(value.slice(0, length).split(''), index);
			return;
		}
		const newValue = value.slice(-1);
		if (newValue.replace(/[^\x00-\x7F]/g, '').trim() === '') {
			return;
		}
		const newOtp = [...otp];

		newOtp[index] = newValue;
		setOtp(newOtp);
		newErrorState[index] = false;
		setErrorState(newErrorState);

		if (index < length - 1 && value) {
			inputRefs.current[index + 1]?.focus();
		}
	};

	const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace') {
			e.preventDefault();
			const newOtp = [...otp];
			newOtp[index] = '';
			setOtp(newOtp);

			if (index > 0) {
				inputRefs.current[index - 1]?.focus();
			}
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'ArrowLeft' && index > 0) {
			inputRefs.current[index - 1]?.focus();
		} else if (e.key === 'ArrowRight' && index < length - 1) {
			inputRefs.current[index + 1]?.focus();
		} else if (e.key === 'Backspace') {
			console.log('backspace');
			handleBackspace(e, index);
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
		e.preventDefault();
		const pasteData = e.clipboardData.getData('text').slice(0, length).split('');
		updateOtp(pasteData, index);
	};

	const renderValidationMessage = () => {
		const hasError = errorState.some((isError) => isError);
		return (
			<Collapse appear={true} in={hasError} unmountOnExit timeout={ANIMATION_TIMEOUT}>
				<Info
					status={'Alert'}
					label={t('OTPInputError')}
					dataTestingLabel={'otp-input-error'}
					isSimple
					className={classes.errorMessage}
				/>
			</Collapse>
		);
	};

	return (
		<ResponseWrapper
			isDisabled={otp.includes('') || errorState.some(Boolean)}
			dataTestingLabel={'otp-input-wrapper'}
			buttonLabel={t('continue', 'Continue')}
			onSubmit={() => onSubmit(otp.join(''))}>
			<div className={classes.wrapper} role={'otp-input-group'} aria-labelledby={'otp-input-group'}>
				{otp.map((value, index) => (
					<div key={index}>
						<Input
							key={index}
							type={'text'}
							error={errorState[index]}
							name={'otp-field'}
							value={value}
							inputMode={'numeric'}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)}
							onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
							inputRef={(el: HTMLInputElement) => (inputRefs.current[index] = el)}
							onPaste={(e: React.ClipboardEvent<HTMLDivElement>) => handlePaste(e, index)}
							autoComplete={'one-time-code'}
							placeholder={'_'}
							aria-label={`OTP input field ${index + 1} of ${length}`}
							aria-invalid={errorState[index] ? 'true' : 'false'}
							inputProps={{
								inputMode: 'numeric',
								autoFocus: index === 0,
								pattern: '[0-9]*',
								'data-testing-label': `otp-input-${index + 1}`,
							}}
						/>
					</div>
				))}
			</div>
			{renderValidationMessage()}
		</ResponseWrapper>
	);
};
