import { FC } from 'react';

import { IconType } from '../icon/icon.component';
import { LoadingIcon } from '../icons/loading.icon';
import { StyledLoadingIndicatorIcon } from './loading-indicator.styled';

interface LoadingIndicatorProps {
	size?: 'large' | 'medium';
	color?: IconType;
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
	size = 'medium',
	color = 'inputIcon',
}: LoadingIndicatorProps) => {
	return (
		<StyledLoadingIndicatorIcon
			dataTestingLabel={'loading-indicator'}
			icon={LoadingIcon}
			size={size}
			iconType={color}
			alt={'widget-loading icon'}
		/>
	);
};
