import { styled } from '@mui/material';

export const StyledAudio = styled('audio')(
	({
		theme: {
			palette: { colorScheme },
		},
	}) => ({
		width: '100%',
		'&:focus-visible': {
			outline: `2px solid ${colorScheme.colorVariables.colorPrimary}`,
			outlineOffset: 2,
			transition: 'outline 0s',
			boxShadow: 'none',
		},
	}),
);
