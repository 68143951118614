import { ElementType, FC } from 'react';

import { ButtonConfig, GenericQuickResponse, QuickResponseMessage } from '../../../models/message.model';
import { Icon } from '../../icon/icon.component';
import { EmojiGoodIcon } from '../../icons/emoji-good.icon';
import { EmojiGreatIcon } from '../../icons/emoji-great.icon';
import { EmojiNeutralIcon } from '../../icons/emoji-neutral.icon';
import { EmojiSadIcon } from '../../icons/emoji-sad.icon';
import { EmojiTerribleIcon } from '../../icons/emoji-terrible.icon';
import { StyledSatisfactionSurveyContainer, StyledSatisfactionSurveyIconButton } from './satisfaction-survey.styled';

export interface SatisfactionSurveyProps {
	quickResponsesMessage: QuickResponseMessage;
	satisfactionConfigButtons: ButtonConfig[];
	onAction(
		response: GenericQuickResponse,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		flowStep: string,
	): void;
}

const getEmoji = (type: string): ElementType => {
	const emojis = {
		TERRIBLE: EmojiTerribleIcon,
		SAD: EmojiSadIcon,
		NEUTRAL: EmojiNeutralIcon,
		GOOD: EmojiGoodIcon,
		GREAT: EmojiGreatIcon,
	};
	return emojis[type] || emojis['NEUTRAL'];
};

export const SatisfactionSurvey: FC<SatisfactionSurveyProps> = ({
	quickResponsesMessage,
	satisfactionConfigButtons,
	onAction,
}) => {
	const { responses, flowStep } = quickResponsesMessage;

	const createEmojiButtons = () =>
		satisfactionConfigButtons.map((button, index) => (
			<StyledSatisfactionSurveyIconButton
				numberOfButtons={satisfactionConfigButtons.length}
				key={index}
				aria-label={responses[index].content}
				onClick={(e) => onAction(responses[index], e, flowStep)}>
				<Icon
					viewBox={'0 0 32 32'}
					icon={getEmoji(button.emojiType)}
					size={'large'}
					iconType={'systemTextDefault'}
					alt={button.emojiType}
				/>
			</StyledSatisfactionSurveyIconButton>
		));

	return <StyledSatisfactionSurveyContainer>{createEmojiButtons()}</StyledSatisfactionSurveyContainer>;
};
