import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useLiveChatStatusControlStyles = makeStyles<Theme, { isConnected: boolean }>(
	({
		typography: { smallViewPort },
		palette: {
			sizes,
			boxShadow,
			borderRadius,
			colorScheme: { colorVariables },
		},
	}) => ({
		root: {
			width: '100%',
			height: sizes.size_10,
			boxShadow: boxShadow.angle.xsmall,
			border: `1px solid ${colorVariables.colorMediumShadow}`,
			padding: '0 12px',
			borderRadius: borderRadius.card,
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
		},
		buttonRoot: {
			height: sizes.size_8,
			borderRadius: borderRadius.button,
		},

		loadingMessage: {
			flex: 1,
			...smallViewPort.typeCaption1,
			paddingLeft: sizes.size_2,
			marginBottom: 0,
			textAlign: 'left',
		},
		paper: {
			margin: '1px 0',
		},
		icon: {
			color: colorVariables.colorSysAlert,
		},
		image: {
			borderRadius: '50%',
			width: '100%',
			height: '100%',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
		},
		agentImageWrapper: {
			width: sizes.size_6,
			height: sizes.size_6,
			backgroundColor: '#FFFFFF',
			borderRadius: '50%',
			position: 'relative',
			'&:after': {
				content: '""',
				position: 'absolute',
				width: 10,
				height: 10,
				borderRadius: '50%',
				right: -sizes.sizeHalf,
				bottom: -sizes.sizeHalf,
				border: `3px solid ${colorVariables.colorBgSurface}`,
				boxSizing: 'content-box',
				backgroundColor: (props) =>
					props.isConnected ? colorVariables.colorSysSuccess : colorVariables.colorInactive,
			},
		},
		buttonAgentImageWrapper: {
			width: sizes.size_5,
			height: sizes.size_5,
			'& > svg': {
				width: sizes.size_5,
				height: sizes.size_5,
			},
		},
		oveflowEllipsis: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		agentData: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			margin: 0,
			paddingLeft: sizes.size_1,
			flex: 1,
			'& > dt': {
				textAlign: 'left',
				...smallViewPort.typeHeading2,
			},
			'& > dd': {
				textAlign: 'left',
				...smallViewPort.typeCaption1,
				marginBottom: 0,
				marginLeft: 0,
			},
		},
		buttonAgentData: {
			'& > dt': {
				...smallViewPort.typeCaption1,
				marginBottom: 0,
				fontWeight: 500,
				fontSize: 15,
				letterSpacing: 0.32,
				color: colorVariables.colorHead,
			},
		},
		hasNotifications: {
			'&:after': {
				content: '""',
				position: 'absolute',
				width: sizes.size_1,
				height: sizes.size_1,
				borderRadius: '50%',
				left: sizes.sizeHalf,
				backgroundColor: colorVariables.colorSysWarning,
				top: '50%',
				transform: 'translateY(-50%)',
			},
		},
		transitionWrapper: {
			display: 'flex',
			position: 'absolute',
			alignItems: 'center',
			transition: 'opacity 0.67s',
			opacity: 0,
		},
		statusWrapper: {
			position: 'relative',
			display: 'flex',
			flex: 1,
			alignItems: 'center',
		},
		shown: {
			opacity: 1,
		},
		leaveChatChip: {
			color: colorVariables.colorPrimary,
			fontWeight: 'bold',
		},
	}),
);
