import { Paper, styled } from '@mui/material';

import { Button } from '../button/button.component';

export const StyledMenuOptionsButton = styled(Button)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		height: sizes.size_6,
	}),
);
export const StyledMenuItemOption = styled('span')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		width: '100%',
		overflow: 'hidden',
		marginRight: sizes.size_1,
		textOverflow: 'ellipsis',
	}),
);

export const StyledPaper = styled(Paper)(
	({
		theme: {
			palette: {
				sizes,
				boxShadow,
				colorScheme: {
					colorVariables: { colorLightShadow, colorPrimarySoft },
				},
			},
			typography: { smallViewPort },
		},
	}) => ({
		...smallViewPort.body,
		width: 264,
		border: `1px solid ${colorLightShadow}`,
		boxShadow: boxShadow.angle.large,
		transform: `translateX(${sizes.size_3}px) !important`,
		borderRadius: sizes.size_2,
		margin: `-${sizes.sizeHalf}px 0px`,
		background: '#FFFFFF',
		'& ul': {
			padding: 0,
			'& li': {
				height: sizes.size_6,
				'&:hover': {
					background: colorPrimarySoft,
				},
			},
		},
	}),
);
