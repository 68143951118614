import { styled } from '@mui/material';

export const ImageRoot = styled('div')({
	maxWidth: 410,
});

export const StyledImage = styled('img')(
	({
		theme: {
			palette: {
				sizes,
				boxShadow,
				colorScheme: {
					colorVariables: { colorMediumShadow },
				},
			},
		},
	}) => ({
		display: 'block',
		borderRadius: sizes.size_1,
		maxWidth: '100%',
		boxShadow: boxShadow.angle.xsmall,
		border: `1px solid ${colorMediumShadow}`,
	}),
);
