import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
	AutocompleteInputChangeReason,
	AutocompleteProps,
	Box,
	InputAdornment,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { identity } from 'fp-ts/lib/function';
import React, { SyntheticEvent } from 'react';
import { FC, KeyboardEvent, memo, useContext, useEffect, useRef, useState } from 'react';

import { LiveChatContext } from '../../context/live-chat-context';
import { INPUT_LINE_HEIGHT, SMALL_HEIGHT_MEDIA_QUERY } from '../../models/dimensions.model';
import { constVoid, Effect, Lazy } from '../../utils/function.utils';
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from '../../utils/sizes.utils';
import { highlightSubstring, isBlank, isNotBlank, MAX_INPUT_MESSAGE_LENGTH } from '../../utils/string.utils';
import { Nullable } from '../../utils/types.utils';
import { Icon } from '../icon/icon.component';
import {
	highlighted,
	InputSx,
	StyledAdornmentContainer,
	StyledAutocomplete,
	StyledClearButton,
	StyledListbox,
	StyledOptionBox,
	StyledPaper,
	StyledSearchIconAdornment,
	StyledSendButton,
	StyledTextField,
} from './input-autocomplete.styles';
import { ListboxComponent } from './virtualized-list.component';

export interface AutocompleteOption {
	label: string;
	value: string;
}

interface InputAutocompleteProps
	extends Omit<AutocompleteProps<AutocompleteOption, false, false, false>, 'renderInput'> {
	value: Nullable<AutocompleteOption>;
	isLoading: boolean;
	isTextInputDisabled: boolean;
	shouldFocusTextInput?: boolean;
	placeholder?: string;
	isSendFreeTextDisabled?: boolean;
	onValueChange: (value: string, reason?: AutocompleteInputChangeReason) => void;
	onOptionSelect: Effect<AutocompleteOption>;
	onClear: Lazy<void>;
	onFocus?: Lazy<void>;
	onSendMessage: (text: string, orininalText?: string, shouldShowInWidget?: boolean) => void;
}

export const InputAutocomplete: FC<InputAutocompleteProps> = memo(
	({
		value,
		inputValue,
		options,
		isTextInputDisabled,
		shouldFocusTextInput,
		placeholder,
		isSendFreeTextDisabled = false,
		onClear,
		onOptionSelect,
		onValueChange,
		onSendMessage,
		onFocus = constVoid,
	}) => {
		const [isSendButtonVisible, handleSendButtonVisibilityState] = useState(false);
		const [isFocused, handleFocusedState] = useState(false);
		const [inputMaxRows, setInputMaxRows] = useState(1);
		const highlightedOptionRef = useRef<Nullable<AutocompleteOption>>(null);

		const theme = useTheme();

		const {
			state: { isLiveChatShown },
		} = useContext(LiveChatContext);

		const inputRef = useRef<Nullable<HTMLInputElement>>(null);

		const handleBlurState = () => {
			handleFocusedState(false);
			!inputValue && handleSendButtonVisibilityState(false);
		};

		const handleSendMessage = (inputValue?: string) => {
			inputValue && isNotBlank(inputValue) && onSendMessage(inputValue);
			if (isFocused) {
				inputRef.current?.blur();
			}
			if (!isLiveChatShown) {
				handleSendButtonVisibilityState(false);
				handleBlurState();
			}
		};

		const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutocompleteOption) => (
			<StyledOptionBox {...props} data-testing-label={'input-autocomplete-option'}>
				<Box>{inputValue ? highlightSubstring(option.label, inputValue, highlighted) : option.label}</Box>
			</StyledOptionBox>
		);

		const endAdornment =
			isSendFreeTextDisabled && inputValue ? (
				<InputAdornment position={'end'}>
					<StyledClearButton
						aria-label={'clear'}
						onClick={onClear}
						data-testing-label={'user-input-clear-btn'}>
						<Icon size={'small'} iconType={'decorIcon'} icon={CloseRoundedIcon} />
					</StyledClearButton>
				</InputAdornment>
			) : null;

		const handleFocusState = () => {
			onFocus();
			handleFocusedState(true);
			handleSendButtonVisibilityState(true);
		};

		const handleValueChange = (
			e: SyntheticEvent<Element, Event>,
			value: string,
			reason: AutocompleteInputChangeReason,
		) => {
			handleSendButtonVisibilityState(true);
			onValueChange(value, reason);
		};

		const handleAutocompleteChange = (autocompleteValue: AutocompleteOption | null) => {
			handleBlurState();
			autocompleteValue && onOptionSelect(autocompleteValue);
		};

		const isSmallHeight = useMediaQuery(SMALL_HEIGHT_MEDIA_QUERY);

		useEffect(() => {
			if (shouldFocusTextInput) {
				setTimeout(() => {
					shouldFocusTextInput && inputRef.current?.focus();
				}, 400);
			}
		}, [shouldFocusTextInput]);

		useEffect(() => {
			const inputRect = inputRef.current?.getBoundingClientRect();
			const inputBottom = inputRect?.bottom || 0;
			const headerHeight = isSmallHeight ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP;
			const maxHeight = inputBottom - headerHeight;
			const maximumRows = Math.floor((maxHeight - 12) / INPUT_LINE_HEIGHT);

			setInputMaxRows(maximumRows);
		}, [isSmallHeight]);

		const virtualizedProps =
			options.length > 200
				? { ListboxComponent: ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>> }
				: {};

		const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
			if (event.key === 'Enter') {
				event.preventDefault();
				if (options.length !== 0 && highlightedOptionRef.current) {
					handleSendMessage(highlightedOptionRef.current.label);
					highlightedOptionRef.current = null;
				} else {
					event.preventDefault();
					if (inputValue && isNotBlank(inputValue) && !isSendFreeTextDisabled) {
						handleSendMessage(inputValue);
					}
				}
			}
			if (options.length === 0) {
				// Allow default behavior for arrow keys when no autocomplete options are shown
				event.stopPropagation();
			}
		};

		return (
			<StyledAutocomplete
				disabled={isTextInputDisabled}
				value={value}
				options={options}
				selectOnFocus={false}
				onHighlightChange={(e, option) => {
					if (e && option) {
						highlightedOptionRef.current = option.label;
					}
				}}
				inputValue={inputValue}
				clearOnBlur={false}
				clearText={'clear'}
				getOptionLabel={(option) => option.label}
				onChange={(e, v) => handleAutocompleteChange(v)}
				onInputChange={handleValueChange}
				noOptionsText={'No matching options'}
				disablePortal
				filterOptions={identity}
				slotProps={{
					paper: {
						component: (props) => (
							<StyledPaper {...props} options={options} hasInputValue={Boolean(inputValue)} />
						),
					},
					listbox: {
						component: StyledListbox,
					},
				}}
				renderOption={(props, option) => renderOption(props, option)}
				{...virtualizedProps}
				renderInput={(params) => (
					<StyledAdornmentContainer>
						{isSendFreeTextDisabled && (
							<StyledSearchIconAdornment data-testing-label={'user-input-search-icon'}>
								<Icon iconType={'decorIcon'} icon={SearchRoundedIcon} />
							</StyledSearchIconAdornment>
						)}
						<StyledTextField
							inputRef={inputRef}
							multiline
							maxRows={isFocused ? inputMaxRows : 1}
							onFocus={handleFocusState}
							onBlur={handleBlurState}
							name={'user message'}
							data-testing-label={'user-input-container'}
							{...params}
							type={'search'}
							onKeyDown={handleKeyDown}
							placeholder={placeholder}
							slotProps={{
								input: {
									...params.InputProps,
									endAdornment,
									sx: {
										...InputSx({ theme, isFocused, isLiveChatShown, isSendFreeTextDisabled }),
									},
								},

								htmlInput: { ...params.inputProps, maxLength: MAX_INPUT_MESSAGE_LENGTH },
							}}
						/>
						{isSendButtonVisible && !isSendFreeTextDisabled && (
							<StyledSendButton
								data-testing-label={'user-input-send-btn'}
								aria-label={'send'}
								disabled={!inputValue || isBlank(inputValue) || isTextInputDisabled}
								onClick={() => handleSendMessage(inputValue)}>
								<Icon
									size={'large'}
									iconType={inputValue ? 'buttonIcon' : 'inputIcon'}
									icon={ArrowUpwardRoundedIcon}
								/>
							</StyledSendButton>
						)}
					</StyledAdornmentContainer>
				)}
			/>
		);
	},
);
