import { FC, memo } from 'react';

import {
	StyledBaseBlock,
	StyledBaseLine,
	StyledLoadingListMaskRoot,
	StyledLongLine,
	StyledSecondBlock,
	StyledShortLine,
	StyledThirdBlock,
} from './loading-list-mask.styles';

interface LoadingAppointmentsMaskProps {
	className?: string;
}

export const LoadingListMask: FC<LoadingAppointmentsMaskProps> = memo(({ className }) => {
	return (
		<StyledLoadingListMaskRoot className={className} data-testing-label={'loading-list-mask'}>
			<StyledLongLine />
			<StyledShortLine />
			<StyledBaseLine />
			<StyledBaseBlock />
			<StyledSecondBlock />
			<StyledThirdBlock />
		</StyledLoadingListMaskRoot>
	);
});
