import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { THUMBS_ANIMATION_TIME } from '../../../ui/user-survey/helfulness-survey.model';
import { Icon } from '../../icon/icon.component';
import {
	StyledHelpfulnessSurveyExternalAvatar,
	StyledHelpfulnessSurveyExternalContent,
	StyledHelpfulnessSurveyExternalRoot,
	StyledIconButton,
} from './helpfulness-survey-external.styles';

export interface HelpfulnessSurveyExternalProps {
	action(rating: number): void;
	onCloseSurvey(): void;
	isOpen: boolean;
	hasAvatarButton?: boolean;
}

const timeout = {
	enter: THUMBS_ANIMATION_TIME,
	exit: THUMBS_ANIMATION_TIME,
};

export const HelpfulnessSurveyExternal: FC<HelpfulnessSurveyExternalProps> = ({
	action,
	onCloseSurvey,
	isOpen,
	hasAvatarButton,
}) => {
	return (
		<CSSTransition
			in={isOpen}
			classNames={{
				enter: 'fabric-fd-enter',
				enterActive: 'fabric-fd-enter-active',
				exitActive: 'fabric-fd-exit-active',
			}}
			mountOnEnter
			unmountOnExit
			timeout={timeout}
			data-testing-label={'survey-animation'}>
			<StyledHelpfulnessSurveyExternalRoot timeout={timeout} hasWidget={!hasAvatarButton}>
				{hasAvatarButton && (
					<StyledHelpfulnessSurveyExternalAvatar dataTestingLabel={'helpfulness-survey-external-avatar'} />
				)}
				<StyledHelpfulnessSurveyExternalContent action={action} />
				<StyledIconButton aria-label={'close survey'} color={'primary'} onClick={onCloseSurvey}>
					<Icon icon={CloseRoundedIcon} size={'small'} iconType={'inputIcon'} alt={'close icon'} />
				</StyledIconButton>
			</StyledHelpfulnessSurveyExternalRoot>
		</CSSTransition>
	);
};
