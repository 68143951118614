import { Collapse } from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ANIMATION_TIMEOUT } from '../../utils/animations.utils';
import { Effect } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { StyledDateErrorMessage } from '../date-input/date-input.styles';
import { Input } from '../input/input.component';
import { ResponseWrapper } from '../response-wrapper/response-wrapper.component';
import {
	StyledBox,
	StyledPaper,
	StyledTimeInputWrapper,
	StyledToggleButton,
	StyledToggleButtonGroup,
} from './time-input.styled';

interface TimeInfo {
	hours: string;
	minutes: string;
}

interface TimeFormItemDetails {
	value: string;
	isValid: boolean;
	isDirty: boolean;
}
interface TimeFormErrorDetails {
	label: string;
	min: number;
	max: number;
}

interface TimeInputProps {
	shouldAutoFocus?: boolean;
	className?: string;
	onSubmit: Effect<string>;
}

type TimeInputFields = keyof TimeInfo;
type TimeFormInfo = Record<TimeInputFields, TimeFormItemDetails>;
type TimeFormErrorsInfo = Record<TimeInputFields, TimeFormErrorDetails>;

const SHORT_VALUES = ['hours', 'minutes'];
const amTimePeriod = 'am';
const pmTimePeriod = 'pm';

const getInitialState = (): TimeFormInfo => ({
	hours: { value: '', isValid: true, isDirty: false },
	minutes: { value: '', isValid: true, isDirty: false },
});

export const TimeInput: FC<TimeInputProps> = ({ shouldAutoFocus, className, onSubmit }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [state, setState] = useState<TimeFormInfo>(getInitialState);
	const [timePeriod, setTimePeriod] = useState<string>('');

	const inputRef = useRef<Nullable<HTMLInputElement>>(null);

	const ERROR_DATA: TimeFormErrorsInfo = useMemo(
		() => ({
			hours: {
				label: t('hours', 'hours'),
				min: 1,
				max: 12,
			},
			minutes: {
				label: t('minutes', 'minutes'),
				min: 0,
				max: 59,
			},
		}),
		[language],
	);

	const handleTimePeriodToggle = (newTimePeriod: string) => {
		setTimePeriod(newTimePeriod);
	};

	const validateEntry = (value: string, field: TimeInputFields): boolean => {
		if (!/^\d+$/.test(value)) return false;
		const errorInfo = ERROR_DATA[field];
		const numValue = Number(value);
		return numValue >= errorInfo.min && numValue <= errorInfo.max;
	};

	const handleBlur = (field: TimeInputFields) => () => {
		setState((prev) => {
			const value = prev[field].value.trim();
			if (!value) return prev;

			const updatedValue = SHORT_VALUES.includes(field) && value.length === 1 ? `0${value}` : value;
			return {
				...prev,
				[field]: {
					...prev[field],
					value: updatedValue,
					isDirty: true,
					isValid: validateEntry(updatedValue, field),
				},
			};
		});
	};

	const createErrorMessage = (field: TimeInputFields) => {
		const timeError = ERROR_DATA[field];
		return t('timeInputError', 'Please enter a value between {{min}} and {{max}} {{label}}.', timeError);
	};

	const isSubmitButtonDisabled =
		Object.values(state).some((entry) => entry.value === '' || !entry.isValid) || !timePeriod;

	const checkForError = (state: TimeFormInfo) => {
		let errorMessage = '';
		Object.entries(state).some(([field, details]) => {
			if (!details.isValid) {
				errorMessage = createErrorMessage(field as TimeInputFields);
			}
		});

		return (
			errorMessage && (
				<Collapse appear={true} in={Boolean(errorMessage)} unmountOnExit timeout={ANIMATION_TIMEOUT}>
					<StyledDateErrorMessage
						aria-live={'assertive'}
						status={'Alert'}
						label={errorMessage}
						isSimple
						dataTestingLabel={'time-input-error'}
					/>
				</Collapse>
			)
		);
	};

	const onTimeChange = (field: TimeInputFields) => (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.currentTarget.value;
		setState((prev) => ({
			...prev,
			[field]: {
				...prev[field],
				value: newValue,
				isDirty: true,
				isValid: validateEntry(newValue, field),
			},
		}));
	};

	const submitTime = () => {
		const { hours, minutes } = state;

		onSubmit(`${hours.value}:${minutes.value} ${timePeriod}`);
	};

	useEffect(() => {
		if (shouldAutoFocus) {
			inputRef.current?.focus();
		}
	}, [shouldAutoFocus]);

	return (
		<ResponseWrapper
			isDisabled={isSubmitButtonDisabled}
			className={className}
			buttonLabel={t('continue', 'Continue')}
			dataTestingLabel={'time-input-root'}
			onSubmit={submitTime}>
			<StyledTimeInputWrapper>
				<StyledBox>
					<Input
						aria-label={t('hours', 'Hours')}
						placeholder={'08'}
						value={state.hours.value}
						inputRef={inputRef}
						error={!state.hours.isValid}
						type={'tel'}
						onBlur={handleBlur('hours')}
						textLength={2}
						onChange={onTimeChange('hours')}
						noFloatingNumbers
						label={'Hours'}
						inputProps={{
							'data-testing-label': 'time-input-hours',
						}}
					/>
					<>:</>
					<Input
						placeholder={'25'}
						aria-label={t('minutes', 'Minutes')}
						value={state.minutes.value}
						onBlur={handleBlur('minutes')}
						error={!state.minutes.isValid}
						textLength={2}
						type={'tel'}
						noFloatingNumbers
						onChange={onTimeChange('minutes')}
						label={'Minutes'}
						inputProps={{
							'data-testing-label': 'time-input-minutes',
						}}
					/>
				</StyledBox>
				<StyledPaper elevation={0}>
					<StyledToggleButtonGroup
						aria-label={'Select time period'}
						data-testing-label={'time-period-toggle-group'}
						value={timePeriod}
						orientation={'vertical'}
						onChange={(e, v: string) => handleTimePeriodToggle(v)}
						exclusive>
						<StyledToggleButton
							aria-label={'Am time period'}
							data-testing-label={'time-period-am-button'}
							value={amTimePeriod}>
							{amTimePeriod}
						</StyledToggleButton>
						<StyledToggleButton
							aria-label={'Pm time period'}
							data-testing-label={'time-period-pm-button'}
							value={pmTimePeriod}>
							{pmTimePeriod}
						</StyledToggleButton>
					</StyledToggleButtonGroup>
				</StyledPaper>
			</StyledTimeInputWrapper>
			{checkForError(state)}
		</ResponseWrapper>
	);
};
