import { styled } from '@mui/material';

export const StyledWrapper = styled('div')<{
	isCarousel?: boolean;
	isResponseType?: boolean;
	isResponseTypeWithQR?: boolean;
	isLiveChatGreeting?: boolean;
	isAppointmentManagement?: boolean;
	isDoctorSearch?: boolean;
}>(
	({
		theme,
		isCarousel,
		isResponseType,
		isResponseTypeWithQR,
		isLiveChatGreeting,
		isAppointmentManagement,
		isDoctorSearch,
	}) => ({
		padding: `0px calc(${theme.palette.sizes.size_1}% + ${theme.palette.sizes.size_1}px) 0`,

		...(isCarousel && {
			display: 'table',
			margin: '0 auto',
			padding: `${theme.palette.sizes.size_2}px 0px 0px`,
		}),

		...(isResponseType && {
			marginTop: 'auto',
			padding: `0px ${theme.palette.sizes.size_1}%`,
		}),

		...(isResponseTypeWithQR && {
			'& > form': {
				marginBottom: 0,
			},
			'& + div': {
				// need to use important, otherwise we can't override the styles of the outer component
				marginTop: 'unset !important',
			},
		}),

		...(isLiveChatGreeting && {
			marginTop: 'auto',
			'& + div': {
				marginTop: 'unset !important',
			},
		}),

		...(isAppointmentManagement && {
			padding: `0px ${theme.palette.sizes.size_1}% 0`,
		}),

		...(isDoctorSearch && {
			padding: `0px ${theme.palette.sizes.size_1}% 0`,
			flex: 1,
		}),
	}),
);
