import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRoot = {
	padding: 0,
	'& > input': {
		paddingRight: 0,
	},
};

export const StyledAdornmentButton = styled(Button)(
	({
		theme: {
			palette: { sizes, colorScheme, borderRadius },
		},
	}) => ({
		padding: `18px ${sizes.size_2}px`,
		marginRight: sizes.sizeQuarter,
		'&:hover': {
			backgroundColor: colorScheme.colorVariables.colorLightShadow,
			boxShadow: 'none',
		},
		'&:focus-visible': {
			borderRadius: borderRadius.commonRadius,
		},
	}),
);
