import { styled } from '@mui/material/styles';

import { CARD_MAX_WIDTH } from '../../../utils/sizes.utils';

export const VideoRoot = styled('div')({
	maxWidth: CARD_MAX_WIDTH,
	maxHeight: 280,
});

export const StyledVideo = styled('video')(
	({
		theme: {
			palette: {
				sizes,
				boxShadow,
				colorScheme: {
					colorVariables: { colorMediumShadow, colorPrimary },
				},
			},
		},
	}) => ({
		display: 'block',
		borderRadius: sizes.size_1,
		maxWidth: '100%',
		maxHeight: 280,
		boxShadow: boxShadow.angle.xsmall,
		border: `1px solid ${colorMediumShadow}`,
		'&:focus-visible': {
			outline: `2px solid ${colorPrimary}`,
			outlineOffset: 2,
			transition: 'outline 0s',
			boxShadow: 'none',
		},
	}),
);
