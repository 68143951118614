import { keyframes, styled } from '@mui/system';

import { shouldForwardProps } from '../../utils/mui-styled.utils';
import { StackPanelCustomStyle } from './stack-panel.component';

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const StackPanelWrapper = styled('nav', {
	shouldForwardProp: shouldForwardProps(['isMobile', 'isFullScreen', 'customStyle']),
})<{
	isMobile: boolean;
	isFullScreen: boolean;
	customStyle?: Partial<Pick<StackPanelCustomStyle, 'main'>>;
}>(
	({
		theme: {
			palette: { colorScheme },
		},
		customStyle,
	}) => ({
		width: '80%',
		maxWidth: 336,
		display: 'flex',
		flexDirection: 'column',
		top: 0,
		bottom: 0,
		left: 0,
		zIndex: 10,
		position: 'absolute',
		background: colorScheme.colorVariables.colorBgSurface,
		borderRight: `1px solid ${colorScheme.colorVariables.colorMediumShadow}`,
		...(customStyle?.main || {}),
	}),
);

export const Overlay = styled('div', { shouldForwardProp: shouldForwardProps(['customStyle']) })<{
	customStyle?: Partial<Pick<StackPanelCustomStyle, 'overlay'>>;
}>(
	({
		theme: {
			palette: { colorScheme },
		},
		customStyle,
	}) => ({
		background: colorScheme.colorVariables.colorMediumShadow,
		position: 'absolute',
		width: '100%',
		height: '100%',
		zIndex: 9,
		top: 0,
		...(customStyle?.overlay || {}),
	}),
);

export const transitionClassNames = {
	enter: 'fabric-fd-panel-enter',
	enterActive: 'fabric-fd-panel-enter-active',
	exit: 'fabric-fd-panel-exit',
	exitActive: 'fabric-fd-panel-exit-active',
};

export const overlayClassNames = {
	enter: 'fabric-fd-panel-overlay-enter',
	enterActive: 'fabric-fd-panel-overlay-enter-active',
	exit: 'fabric-fd-panel-overlay-exit',
	exitActive: 'fabric-fd-panel-overlay-exit-active',
};

export const PanelTransition = styled(StackPanelWrapper)(
	({
		theme: {
			palette: { transitionConfig },
		},
	}) => ({
		'&.fabric-fd-panel-enter': {
			animation: `${slideIn} ${transitionConfig.transitionInDuration}ms forwards`,
		},
		'&.fabric-fd-panel-exit': {
			animation: `${slideOut} ${transitionConfig.transitionOutDuration}ms forwards`,
		},
	}),
);

export const OverlayTransition = styled(Overlay)(
	({
		theme: {
			palette: { transitionConfig },
		},
	}) => ({
		'&.fabric-fd-panel-overlay-enter': {
			animation: `${fadeIn} ${transitionConfig.transitionInDuration}ms forwards`,
		},
		'&.fabric-fd-panel-overlay-exit': {
			animation: `${fadeOut} ${transitionConfig.transitionOutDuration}ms forwards`,
		},
	}),
);
