import { FC, memo } from 'react';

import { StyledAudio } from './audio.styled';

interface AudioProps {
	src: string;
}

export const Audio: FC<AudioProps> = memo(({ src }) => {
	return (
		<StyledAudio controls preload={'metadata'} controlsList={'nodownload nofullscreen noremoteplayback'}>
			<track kind={'captions'} />
			<source src={src} type={'audio/mpeg'} />
		</StyledAudio>
	);
});
