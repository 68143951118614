import { keyframes } from '@emotion/react';
import { styled } from '@mui/material';

const blinker = keyframes`
  50% {
    opacity: 0.2;
  }
`;

export const StyledLoadingListMaskRoot = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	background: 'white',
});

export const StyledBaseLine = styled('div')(
	({
		theme: {
			palette: { colorScheme, sizes },
		},
	}) => ({
		background: colorScheme.colorVariables.colorInactive,
		borderRadius: 2,
		margin: `0 ${sizes.size_1}px ${sizes.size_2}px`,
		animation: `${blinker} 1s linear infinite`,
		height: 13,
		width: '50%',
	}),
);

export const StyledLongLine = styled(StyledBaseLine)({
	width: '80%',
});

export const StyledShortLine = styled(StyledBaseLine)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		marginBottom: sizes.size_4,
	}),
);

export const StyledBaseBlock = styled('div')(
	({
		theme: {
			palette: { colorScheme, sizes, borderRadius },
		},
	}) => ({
		background: colorScheme.colorVariables.colorInactive,
		borderRadius: borderRadius.commonRadius,
		height: 111,
		marginBottom: sizes.size_1,
		animation: `${blinker} 1s linear infinite`,
	}),
);

export const StyledSecondBlock = styled(StyledBaseBlock)(() => ({
	opacity: 0.65,
}));

export const StyledThirdBlock = styled(StyledBaseBlock)(() => ({
	opacity: 0.3,
}));
