import { UnfoldMoreRounded } from '@mui/icons-material';
import { InputAdornment, Menu } from '@mui/material';
import { FC, memo, useMemo, useState } from 'react';

import { Effect } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import {
	StyledUnitSelectorLabel,
	StyledUnitSelectorMenuButton,
	StyledUnitSelectorMenuItem,
} from './unit-selector.styled';

export type UnitSelectorRenderType = 'label' | 'select';
interface UnitSelectorProps {
	type: UnitSelectorRenderType;
	units: string[];
	testingLabel?: string;
	isFocused: boolean;
	onChange: Effect<string>;
}

export const UnitSelector: FC<UnitSelectorProps> = memo(({ units, type, testingLabel, isFocused, onChange }) => {
	if (!units.length) {
		console.error('Units array is empty or undefined.');
		return null;
	}

	const [unit, setUnit] = useState(units[0]);
	const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (unitItem: string): void => {
		setUnit(unitItem);
		onChange(unitItem);
		handleClose();
	};

	const menuItems = useMemo(
		() =>
			units.map((unitItem) => (
				<StyledUnitSelectorMenuItem
					key={unitItem}
					value={unitItem}
					selected={unitItem === unit}
					onClick={() => handleMenuItemClick(unitItem)}>
					{unitItem}
				</StyledUnitSelectorMenuItem>
			)),
		[units, unit],
	);

	return type === 'label' ? (
		<StyledUnitSelectorLabel isFocused={isFocused}>{units[0]}</StyledUnitSelectorLabel>
	) : (
		<InputAdornment position={'end'}>
			<StyledUnitSelectorMenuButton
				isFocused={isFocused}
				aria-controls={'unit-selector-menu'}
				aria-haspopup={'true'}
				data-testing-label={testingLabel}
				onClick={handleClick}
				endIcon={<UnfoldMoreRounded />}>
				{unit}
			</StyledUnitSelectorMenuButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'unit-selector-button',
				}}
				slotProps={{ paper: { sx: { borderRadius: 0 } } }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}>
				{menuItems}
			</Menu>
		</InputAdornment>
	);
});
