import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ANIMATIONS } from '../../utils/animations.utils';

export const useMFAStyles = makeStyles<Theme>(({ palette: { sizes } }) => ({
	...ANIMATIONS['gyant-animation-heartbeat'],
	wrapper: {
		display: 'flex',
		alignSelf: 'center',
		gap: sizes.size_1,
		maxWidth: 304,

		'& input': {
			textAlign: 'center',
			padding: 0,
			height: sizes.size_8,
		},
	},
	errorMessage: {
		width: 320,
		marginInline: 'auto',
		paddingTop: sizes.size_2,
		'& > svg': {
			animation: '$gyant-animation-heartbeat 1.5s linear infinite',
		},
	},
}));
