import { FC, memo } from 'react';

import { ImageRoot, StyledImage } from './image.styled';

interface ImageProps {
	src: string;
}

export const Image: FC<ImageProps> = memo(({ src }) => {
	return (
		<ImageRoot>
			<StyledImage alt={'media'} src={src} />
		</ImageRoot>
	);
});
