import { Modal, styled } from '@mui/material';

import { MAX_WIDGET_WIDTH } from '../../utils/sizes.utils';
import { IconButton } from '../icon-button/icon-button.component';

export const StyledFancyBoxModal = styled(Modal)(
	({
		theme: {
			palette: {
				colorScheme: { colorVariables },
			},
		},
	}) => ({
		width: '100%',
		maxWidth: MAX_WIDGET_WIDTH,
		position: 'absolute',
		paddingTop: '4vh',
		margin: '0 auto',
		'& > div:first-child': {
			backgroundColor: `${colorVariables?.colorMediumShadow} !important`,
		},
	}),
);

export const StyledFancyBoxContentWrapper = styled('div')(
	({
		theme: {
			palette: { boxShadow, sizes },
		},
	}) => ({
		borderTopLeftRadius: sizes.size_2,
		borderTopRightRadius: sizes.size_2,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		boxShadow: boxShadow.angle.large,
		outline: 'none',
		'&:focus-visible': {
			outline: 'none',
		},
	}),
);

export const StyledFAncyBoxHeader = styled('div')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		padding: `0px ${sizes.size_2}px`,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		backgroundColor: 'white',
		height: sizes.size_7,
	}),
);

export const StyledFancyBoxIconButton = styled(IconButton)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		padding: 0,
		color: 'white',
		width: sizes.size_6,
		height: sizes.size_5,
		'&:hover': {
			opacity: 1,
			backgroundColor: 'transparent',
		},
	}),
);
