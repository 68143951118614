import { findLast } from 'fp-ts/lib/Array';
import { constant, pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Option';
import { RefObject } from 'react';

import {
	isMessageWithAppointmentManagementMetadata,
	isMessageWithDoctorSearchMetadata,
	isMessageWithLiveChatGreetingMetadata,
	isMessageWithLLMResultMetadata,
	isMessageWithPasswordResetMetadata,
	isMessageWithSchedulingMetadata,
	isQuickResponseMessage,
	isSomeCustomUIComponentByResponseType,
	isTextMessage,
	Message,
	MessageWithAppointmentManagementMetadata,
	MessageWithAppointmentSchedulingMetaData,
	MessageWithAppointmentSchedulingSummaryMetaData,
	MessageWithDoctorSearchMetadata,
	MessageWithLiveChatGreetingMetadata,
	MessageWithLLMResultMetadata,
	MessageWithPasswordResetMetadata,
} from '../../models/message.model';
import { DateInput } from '../../ui-kit/date-input/date-input.component';
import { EmailInput } from '../../ui-kit/email-input/email-input.component';
import { OTPInput } from '../../ui-kit/otp-input/otp-input.component';
import { PhoneInput } from '../../ui-kit/phone-input/phone-input.component';
import { TimeInput } from '../../ui-kit/time-input/time-input.component';
import { WeightInput } from '../../ui-kit/weight-input/weight-input.component';
import { AppointmentManagerContainer } from '../../ui/appointment-manager/appointment-manager.container';
import { AppointmentSchedulerContainer } from '../../ui/appointment-scheduling/appointment-scheduling.container';
import { AppointmentSummary } from '../../ui/appointment-scheduling/appointment-summary/appointment-summary.component';
import { DoctorSearchContainer } from '../../ui/doctor-search/doctor-search.container';
import { ImageUploaderContainer } from '../../ui/image-uploader/image-uploader.container';
import { LiveChatGreetingCard } from '../../ui/live-chat/components/greeting-card/greeting-card.component';
import { LLMLabelContainer } from '../../ui/llm-response/llm-label/llm-label.container';
import { LLMResultsContainer } from '../../ui/llm-response/llm-result/llm-result.container';
import { CarouselRenderer } from '../../ui/message-renderers/carousel-renderer/carousel-renderer.component';
import { MediaRenderer } from '../../ui/message-renderers/media-renderer/media-renderer.component';
import { QuickResponseRenderer } from '../../ui/message-renderers/quick-responses-renderer/quick-responses-renderer.component';
import { TextRenderer } from '../../ui/message-renderers/text-renderer/text-renderer.component';
import { PasswordResetContainer } from '../../ui/password-reset/password-reset.container';
import { HelpfulnessSurveyContainer } from '../../ui/user-survey/helpfulness-survey/helfulness-survey.container';
import { OnSenMessageFunc } from '../../ui/widget-wrapper/widget-wrapper.model';
import { lastIndexOf } from '../array.utils';
import { isMobileDeviceByTouchPoints } from '../device.utils';
import { constVoid, Effect } from '../function.utils';
import { uuid } from '../string.utils';
import { StyledWrapper } from './utils.styles';

export const isUserMessage = (message: { incoming: boolean }): boolean => message.incoming;

interface AdditionalComponentWrapperProps {
	message: Message;
	component: JSX.Element;
	className?: string;
}

const AdditionalComponentWrapper = ({
	message,
	component,
	className,
}: AdditionalComponentWrapperProps): JSX.Element => {
	return (
		<StyledWrapper
			className={className}
			isCarousel={message.type === 'carousel'}
			isResponseType={
				isSomeCustomUIComponentByResponseType(message.responseType) ||
				isMessageWithPasswordResetMetadata(message)
			}
			isResponseTypeWithQR={
				isSomeCustomUIComponentByResponseType(message.responseType) && isQuickResponseMessage(message)
			}
			isLiveChatGreeting={isMessageWithLiveChatGreetingMetadata(message)}
			isAppointmentManagement={isMessageWithAppointmentManagementMetadata(message)}
			isDoctorSearch={isMessageWithDoctorSearchMetadata(message)}>
			{component}
		</StyledWrapper>
	);
};

const getHelpfulnessSurveyComponent = (message?: Message): JSX.Element | undefined => {
	if (message && !!message.hasHelpfulnessSurvey && !message.isExternal) {
		return (
			<AdditionalComponentWrapper
				key={'Helpfulness-survey'}
				message={message}
				component={<HelpfulnessSurveyContainer message={message} />}
			/>
		);
	}
};

const getAppointmentManagementComponent = (
	message?: MessageWithAppointmentManagementMetadata,
	rootRef?: RefObject<HTMLDivElement>,
): JSX.Element | undefined => {
	if (message) {
		return (
			<AdditionalComponentWrapper
				key={'Appointment-manager'}
				message={message}
				component={
					<AppointmentManagerContainer
						rootRef={rootRef}
						emptySlotsFlowStep={message.metadata.data.emptySlotsStep}
					/>
				}
			/>
		);
	}
};

const getDoctorSearchComponent = (
	message?: MessageWithDoctorSearchMetadata,
	rootRef?: RefObject<HTMLDivElement>,
): JSX.Element | undefined => {
	if (message) {
		return (
			<AdditionalComponentWrapper
				key={`Doctor-search-${uuid()}`}
				message={message}
				component={<DoctorSearchContainer rootRef={rootRef} data={message.metadata} />}
			/>
		);
	}
};

const getPasswordResetComponent = (message?: MessageWithPasswordResetMetadata): JSX.Element | undefined => {
	if (message) {
		return (
			<AdditionalComponentWrapper
				key={`Password-reset-${uuid()}`}
				message={message}
				component={<PasswordResetContainer data={message.metadata.data} />}
			/>
		);
	}
};

const getLiveChatGreetingComponent = (messages: Message[], onSendMessage: OnSenMessageFunc) => {
	const messageWithMetadataIndex = lastIndexOf(messages, isMessageWithLiveChatGreetingMetadata);
	if (messageWithMetadataIndex !== -1) {
		const messageWithMetadata = messages[messageWithMetadataIndex] as MessageWithLiveChatGreetingMetadata;
		return (
			<AdditionalComponentWrapper
				key={'Live-chat-greeting'}
				message={messageWithMetadata}
				component={
					<LiveChatGreetingCard
						title={messageWithMetadata.metadata.data.title}
						onAction={() =>
							onSendMessage(
								messageWithMetadata.metadata.data.payload,
								messageWithMetadata.metadata.data.title,
							)
						}
						description={messageWithMetadata.metadata.data.description}
						mode={'greeting'}
					/>
				}
			/>
		);
	}
};

const getAdditionalComponentByResponseType = (
	message: Message,
	onSendMessage: OnSenMessageFunc,
): JSX.Element | undefined => {
	const shouldAutoFocus = !message.incoming && isTextMessage(message) && !isMobileDeviceByTouchPoints();
	switch (message.responseType) {
		case 'date':
			return (
				<AdditionalComponentWrapper
					key={'Date-input'}
					message={message}
					component={<DateInput onSubmit={onSendMessage} shouldAutoFocus={shouldAutoFocus} />}
				/>
			);
		case 'mobile':
		case 'phone':
			return (
				<AdditionalComponentWrapper
					key={'Phone-input'}
					message={message}
					component={<PhoneInput onSubmit={onSendMessage} shouldAutoFocus={shouldAutoFocus} />}
				/>
			);
		case 'email':
			return (
				<AdditionalComponentWrapper
					key={'Email-input'}
					message={message}
					component={<EmailInput onSubmit={onSendMessage} shouldAutoFocus={shouldAutoFocus} />}
				/>
			);
		case 'weight':
			return (
				<AdditionalComponentWrapper
					key={'Weight-input'}
					message={message}
					component={<WeightInput onSubmit={onSendMessage} shouldAutoFocus={shouldAutoFocus} />}
				/>
			);
		case 'otp':
			return (
				<AdditionalComponentWrapper
					key={'OTP-input'}
					message={message}
					component={<OTPInput onSubmit={onSendMessage} />}
				/>
			);

		case 'time':
			return (
				<AdditionalComponentWrapper
					key={'Time-input'}
					message={message}
					component={<TimeInput onSubmit={onSendMessage} shouldAutoFocus={shouldAutoFocus} />}
				/>
			);
	}
};

export const renderComponentsByType = (
	messages: Message[],
	onSendMessage: OnSenMessageFunc,
	onUndoMessage: Effect<number>,
	rootRef?: RefObject<HTMLDivElement>,
): JSX.Element[] => {
	const liveChatGreetingComponent = getLiveChatGreetingComponent(messages, onSendMessage);
	const isLLMMetadataAbsent = messages.findIndex(isMessageWithLLMResultMetadata) === -1;
	const helpfulnessSurveyComponent =
		isLLMMetadataAbsent && getHelpfulnessSurveyComponent(messages[messages.length - 1]);
	const appointmentManagementComponent = getAppointmentManagementComponent(
		messages.find(isMessageWithAppointmentManagementMetadata),
		rootRef,
	);

	const doctorSearchComponent = pipe(
		messages,
		findLast(isMessageWithDoctorSearchMetadata),
		fold(constant(undefined), (message) => getDoctorSearchComponent(message, rootRef)),
	);

	const passwordResetComponent = pipe(
		messages,
		findLast(isMessageWithPasswordResetMetadata),
		fold(constant(undefined), getPasswordResetComponent),
	);

	getPasswordResetComponent(messages.find(isMessageWithPasswordResetMetadata));

	const additionalTextMessageByResponseType = getAdditionalComponentByResponseType(
		messages[messages.length - 1],
		onSendMessage,
	);

	const additionalComponent =
		helpfulnessSurveyComponent ||
		liveChatGreetingComponent ||
		appointmentManagementComponent ||
		doctorSearchComponent ||
		passwordResetComponent;

	const renderedMessages = messages.map((message, index) => {
		const isLastMessage = index === messages.length - 1;

		if (isQuickResponseMessage(message) && message.responseType === 'image') {
			return <ImageUploaderContainer message={message} key={message.responses[0].content} />;
		}

		if (isMessageWithLLMResultMetadata(message)) {
			return <LLMResultsContainer message={message} key={'LLM-Results'} />;
		}

		switch (message.type) {
			case 'text':
			case 'messageStatus':
			case 'command':
			case 'message':
				return message.incoming ? (
					<TextRenderer
						key={`${message.text}_${index}`}
						flowStep={message.flowStep}
						message={message.text}
						isUserMessage
						isUndoable={Boolean(message.isUndoable)}
						onUndo={() => onUndoMessage(index)}
						additionalComponents={
							isLastMessage ? [additionalComponent, additionalTextMessageByResponseType] : []
						}
					/>
				) : (
					<TextRenderer
						key={`${message.content}_${index}`}
						flowStep={message.flowStep}
						message={message.content}
						isUserMessage={false}
						isUndoable={false}
						additionalComponents={
							isLastMessage ? [additionalComponent, additionalTextMessageByResponseType] : []
						}
					/>
				);
			case 'image':
			case 'video':
			case 'audio':
				return <MediaRenderer key={`${message.text}_${index}`} src={message.content} type={message.type} />;
			case 'quickResponses':
				return (
					<QuickResponseRenderer
						key={`${message.text}_${index}`}
						message={message}
						previousMessage={messages[index - 1]}
						onResponse={onSendMessage}
						additionalComponents={
							isLastMessage ? [additionalComponent, additionalTextMessageByResponseType] : []
						}
					/>
				);

			case 'carousel':
				return (
					<CarouselRenderer
						key={`${message.text}_${index}`}
						message={message}
						onResponse={onSendMessage}
						additionalComponent={isLastMessage ? additionalComponent : undefined}
					/>
				);
		}
	});

	const messageWithSchedulingMetaData = messages.find(isMessageWithSchedulingMetadata);
	const indexOfMessageWithLLMResultMetadata = messages.findIndex(isMessageWithLLMResultMetadata);
	const indexOfMessageWithSchedulingMetaData = messages.findIndex(isMessageWithSchedulingMetadata);

	const getMetadataComponent = (
		message: MessageWithAppointmentSchedulingMetaData | MessageWithAppointmentSchedulingSummaryMetaData,
	) => {
		switch (message.metadata.status) {
			case 'showAppointmentScheduling':
				return (
					<AppointmentSchedulerContainer
						data={message.metadata.data}
						onSendMessage={onSendMessage}
						key={message.metadata.status}
					/>
				);
			case 'showAppointmentSummary':
				return (
					<AppointmentSummary
						key={message.metadata.status}
						providerName={message.metadata.data.providerName}
						makeChangeFlowStep={message.metadata.data.makeChangeFlowStep}
						cancelAppointmentFlowStep={message.metadata.data.cancelAppointmentFlowStep}
						appointmentValues={message.metadata.data.appointmentValues}
						location={message.metadata.data.location}
						contact={message.metadata.data.contact}
						scope={message.metadata.data.scope}
						templates={message.metadata.data.templates}
						flowId={message.metadata.data.flowId}
						stepName={message.metadata.data.stepName}
						onChangeAppointment={onSendMessage}
						onClickLink={constVoid}
					/>
				);
		}
	};

	if (messageWithSchedulingMetaData) {
		renderedMessages.splice(
			indexOfMessageWithSchedulingMetaData + 1,
			0,
			getMetadataComponent(messageWithSchedulingMetaData),
		);
	}

	if (indexOfMessageWithLLMResultMetadata !== -1) {
		const message = messages[indexOfMessageWithLLMResultMetadata] as MessageWithLLMResultMetadata;
		renderedMessages.splice(0, 0, <LLMLabelContainer message={message} key={'LLM-label'} />);
	}

	return renderedMessages as JSX.Element[];
};
