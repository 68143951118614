import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { ColorVariables } from '../../models/app.model';
import { NotificationStatus } from '../../models/notification.model';
import { ConfirmationAltIcon } from '../icons/confirmationAlt';
import { NoDisturbIcon } from '../icons/no-disturb.icon';
import { StyledInfoIconWrapper, StyledInfoLabel, StyledInfoRoot } from './info.styled';

export interface InfoColorSettings {
	bgColor: string;
	iconColor: string;
	textColor: string;
}

interface InfoProps {
	status: NotificationStatus;
	label: string;
	className?: string;
	dataTestingLabel?: string;
	isSimple?: boolean;
}
// TODO: add different icons when will be ready in design
const getInfoColorSettings = (status: NotificationStatus, colorVariables: ColorVariables): InfoColorSettings => {
	const bgKey = `colorSysBg${status}` as keyof ColorVariables;
	const iconKey = `colorSys${status}` as keyof ColorVariables;
	const textKey = `colorSysText${status}` as keyof ColorVariables;
	return {
		bgColor: colorVariables[bgKey],
		iconColor: colorVariables[iconKey],
		textColor: colorVariables[textKey],
	};
};

const getInfoIcon = (status: NotificationStatus) => {
	switch (status) {
		case 'Success':
			return <ConfirmationAltIcon />;
		case 'Warning':
			return <WarningRoundedIcon />;
		case 'Alert':
			return <ErrorRoundedIcon />;
		default:
			return <NoDisturbIcon />;
	}
};

export const Info: FC<InfoProps> = ({ status, label, isSimple = false, className, dataTestingLabel }) => {
	const {
		palette: {
			colorScheme: { colorVariables },
		},
	} = useTheme();
	const infoColorSettings = getInfoColorSettings(status, colorVariables);
	const notificationIcon = getInfoIcon(status);

	return (
		<StyledInfoRoot
			isSimple={isSimple}
			infoColorSettings={infoColorSettings}
			className={className}
			data-testing-label={dataTestingLabel}>
			<StyledInfoIconWrapper infoColorSettings={infoColorSettings}>{notificationIcon}</StyledInfoIconWrapper>
			<StyledInfoLabel infoColorSettings={infoColorSettings}>{label}</StyledInfoLabel>
		</StyledInfoRoot>
	);
};
