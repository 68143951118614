import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { MAX_WIDGET_WIDTH } from '../../../utils/sizes.utils';

export const useQuickResponseRendererStyles = makeStyles<
	Theme,
	{ isWidgetDisabled: boolean; isAdditionalComponents?: boolean }
>(({ palette: { colorScheme, sizes } }) => ({
	buttonsContainer: ({ isAdditionalComponents }) => ({
		display: 'flex',
		flexWrap: 'wrap',
		columnGap: sizes.size_1,
		justifyContent: 'center',
		width: isAdditionalComponents ? '100vw' : '100%',
		maxWidth: MAX_WIDGET_WIDTH,
		padding: `${sizes.size_2}px  ${sizes.size_1}% ${sizes.size_1}px `,
	}),
	stackedButtonsContainer: {
		flexDirection: 'column',
	},
	stackedButtonsSatisfactionContainer: {
		width: '100%',
		'& > div': {
			width: '100%',
		},
	},
	customQuickResponseStyles: {
		marginBottom: sizes.size_1,
		...colorScheme.customStyle.quickResponseButtons?.main,
	},
	externalResponse: {
		...colorScheme.customStyle.quickResponseButtons?.external,
	},
	lastQrpadding: {
		paddingTop: sizes.size_2,
	},
	survey: {
		flexDirection: 'column',
		alignItems: 'center',
	},
}));
