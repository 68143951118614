import { FC, memo } from 'react';

import {
	StyledLoadingBotMessage,
	StyledLoadingEmptySlot,
	StyledLoadingLowOpacitySlot,
	StyledLoadingMediumOpacitySlot,
	StyledLoadingSlotsRoot,
} from './loading-slots.styles';

interface LoadingSlotsProps {
	className?: string;
	dataTestingLabel?: string;
}

export const LoadingSlots: FC<LoadingSlotsProps> = memo(({ className, dataTestingLabel = 'loading-slots' }) => {
	return (
		<StyledLoadingSlotsRoot className={className} data-testing-label={dataTestingLabel}>
			<StyledLoadingBotMessage />
			<>
				<StyledLoadingEmptySlot />
				<StyledLoadingMediumOpacitySlot />
				<StyledLoadingLowOpacitySlot />
			</>
		</StyledLoadingSlotsRoot>
	);
});
