import Portal from '@mui/material/Portal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, memo, RefObject, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ConfigContext } from '../../context/config.context';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import { Lazy } from '../../utils/function.utils';
import { overlayClassNames, OverlayTransition, PanelTransition, transitionClassNames } from './stack-panel.styles';

export interface StackPanelCustomStyle {
	main: Record<string, any>;
	overlay: Record<string, any>;
	transition: Record<string, any>;
}

export interface StackPanelProps {
	isOpen: boolean;
	onClose: Lazy<void>;
	hasOverlay?: boolean;
	testingLabel?: string;
	rootRef?: RefObject<HTMLDivElement>;
	customStyle?: Partial<StackPanelCustomStyle>;
}

export const StackPanel: FC<StackPanelProps> = memo(
	({ isOpen = false, onClose, hasOverlay = true, children, rootRef, customStyle, testingLabel }) => {
		const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
		const {
			palette: { transitionConfig },
		} = useTheme();
		const {
			appOptions: { fullScreen },
		} = useContext(ConfigContext);

		const DEFAULT_TIMEOUT = {
			enter: transitionConfig.transitionInDuration,
			exit: transitionConfig.transitionOutDuration,
		};

		const createOverlay = () => (
			<CSSTransition
				in={isOpen}
				classNames={overlayClassNames}
				mountOnEnter
				data-testing-label={'menu-overlay'}
				unmountOnExit
				timeout={DEFAULT_TIMEOUT}>
				<OverlayTransition
					customStyle={customStyle}
					onClick={onClose}
					aria-label={'overlay'}
					aria-hidden
					role={'tooltip'}
				/>
			</CSSTransition>
		);

		return (
			<Portal container={rootRef?.current}>
				<CSSTransition
					in={isOpen}
					classNames={transitionClassNames}
					mountOnEnter
					unmountOnExit
					timeout={DEFAULT_TIMEOUT}>
					<PanelTransition
						isMobile={isMobile}
						isFullScreen={fullScreen}
						customStyle={customStyle}
						data-testing-label={testingLabel || 'stack-panel'}>
						{children}
					</PanelTransition>
				</CSSTransition>
				{hasOverlay && createOverlay()}
			</Portal>
		);
	},
);
